import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

//import layout
import FrontendLayout from '@/layouts/Frontend.vue'
import BackendLayout from '@/layouts/Backend.vue'

//import views
//frontend
import Home from '@/views/frontend/Home.vue'
import Login from '@/views/backend/Login.vue'
import NotFound from '@/views/frontend/NotFound404.vue'
import Register from '@/views/frontend/Register'

function authGuard(to, from, next){
  
  let isAuthenticated = false
  if(store.getters['authMember/logedIn']){
    if(to.meta.roles){
      let roles = localStorage.getItem('ccur');
      let roleAllowed = roles.includes(to.meta.roles) //ตรวจสอบว่ามีสิทธ์เข้าถึงหน้่าที่ต้องการไหม
      if(roleAllowed){
        if(to.meta.permissions){
          let permissions = localStorage.getItem('ccups')
          let permissionAllowed = permissions.includes(to.meta.permissions)
          if(!permissionAllowed){
            router.push({
              name: 'NotFound' //ถ้าไม่มีสิทธ์ให้แสดงว่าไม่พบหน้าที่ต้องการ
            })
          }
        }
  
        // refresh expired token
        // store.dispatch('authMember/refreshExpiredToken')
        //   .then(() => {
  
        //   })
        //   .catch(error => {
        //     if(error.response.status == 401){ //ถ้า token หมดอายุ
        //       localStorage.removeItem('ccan')
        //       localStorage.removeItem('ccups')
        //       localStorage.removeItem('ccur')
        //       store.dispatch('authMember/unauthenticateResetting')
        //       router.push({name: 'Login'})
        //       console.log('unauthenticate')
        //     }
        //   })
      }else{
        router.push({
          name: 'NotFound' //ถ้าไม่มีสิทธ์ให้แสดงว่าไม่พบหน้าที่ต้องการ
        }) 
      }
    }
    isAuthenticated = true
  }else{
    isAuthenticated = false
  }

  if(isAuthenticated){
    next() //อนุญาติให้เข้าสู่ route และโหลด component ที่ต้องการได้
  }else{
    next({name: 'Login'})
  }

}

const routes = [

  //frontend
  {
    path: '/design',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'Design',
        component: require('@/views/frontend/design/Organization').default
      }
    ],
    meta:{
      title: 'แก้ไขข้อมูลองค์กร'
    }
  },
  {
    path: '/voucherManage',
    component: FrontendLayout,
    children:[
      {
        path: 'VoucherManage/:organization_id',
        name: 'VoucherManage',
        component: require('@/views/frontend/account/VoucherManage').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'จัดการข้อมูลบัตรกำนัล'
    }
  },
  {
    path: '/giftVoucher',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'GiftVoucher',
        component: require('@/views/frontend/account/GiftVoucher').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'บัตรกำนัล'
    }
  },
  {
    path: '/amlPolicy',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'AmlPolicy',
        component: require('@/views/frontend/AmlPolicy.vue').default
      }
    ],
    meta:{
      title: 'นโยบายคุ้มครองข้อมูลส่วนบุคคล'
    }
  },
  {
    path: '/privacy',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'Privacy',
        component: require('@/views/frontend/Privacy').default
      }
    ],
    meta:{
      title: 'นโยบายคุ้มครองข้อมูลส่วนบุคคล'
    }
  },
  {
    path: '/refund',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'Refund',
        component: require('@/views/frontend/Refund').default
      }
    ],
    meta:{
      title: 'นโยบายการคืนเงิน'
    }
  },
  {
    path: '/ribaConfirmation',
    component: FrontendLayout,
    children:[
      {
        path: 'RibaConfirmation/:order_id',
        name: 'RibaConfirmation',
        component: require('@/views/frontend/riba/Confirmation').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'ยืนยันการสะสางดกเบี้ย'
    }
  },
  {
    path: '/ribaOrderDetail',
    component: FrontendLayout,
    children:[
      {
        path: 'RibaOrderDetail/:order_id',
        name: 'RibaOrderDetail',
        component: require('@/views/frontend/account/RibaOrderDetail').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'รายละเอียดการสะสางดอกเบี้ย'
    }
  },
  {
    path: '/zakatOrderDetail',
    component: FrontendLayout,
    children:[
      {
        path: 'ZakatOrderDetail/:order_id',
        name: 'ZakatOrderDetail',
        component: require('@/views/frontend/account/ZakatOrderDetail').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'รายละเอียดการจ่ายซากาต'
    }
  },
  {
    path: '/donateOrderDetail',
    component: FrontendLayout,
    children:[
      {
        path: 'DonateOrderDetail/:order_id',
        name: 'DonateOrderDetail',
        component: require('@/views/frontend/account/DonateOrderDetail').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'รายละเอียดการบริจาค'
    }
  },
  {
    path: '/zakatRecheckout',
    component: FrontendLayout,
    children:[
      {
        path: 'ZakatRecheckout/:order_id',
        name: 'ZakatRecheckout',
        component: require('@/views/frontend/zakat/ZakatRecheckout').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'ยืนยันจ่ายซากาต'
    }
  },
  {
    path: '/zakatHistory',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'ZakatHistory',
        component: require('@/views/frontend/account/ZakatHistory').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'ประวัติจ่ายซากาต'
    }
  },
  {
    path: '/zakatRecheckout',
    component: FrontendLayout,
    children:[
      {
        path: 'ZakatRecheckout/:order_id',
        name: 'ZakatRecheckout',
        component: require('@/views/frontend/zakat/ZakatRecheckout').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'ยืนยันการโอนเงินซากาต'
    }
  },
  {
    path: '/zakatConfirmaion',
    component: FrontendLayout,
    children:[
      {
        path: 'ZakatConfirmation/:order_id',
        name: 'ZakatConfirmation',
        component: require('@/views/frontend/zakat/Confirmation').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'ยืนยันการจ่ายซากาต'
    }
  },
  {
    path: '/zakat',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'Zakat',
        component: require('@/views/frontend/zakat/Index').default,
      }
    ],
    meta:{
      title: 'จ่ายซากาต'
    }
  },
  {
    path: '/checoutAgain',
    component: FrontendLayout,
    children:[
      {
        path: 'Checkout/:order_id',
        name: 'CheckoutAgain',
        component: require('@/views/frontend/cart/CheckoutAgain').default
      }
    ],
    meta:{
      title: 'แก้ไขข้อมูลองค์กร'
    }
  },
  {
    path: '/ribaRecheckout',
    component: FrontendLayout,
    children:[
      {
        path: 'RibaRecheckout/:order_id',
        name: 'RibaRecheckout',
        component: require('@/views/frontend/riba/RibaRecheckout').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'ยืนยันการโอนเงิน'
    }
  },
  {
    path: '/ribaHistory',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'RibaHistory',
        component: require('@/views/frontend/account/RibaHistory').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'ประวัติการสะสางดอกเบี้ย'
    }
  },
  {
    path: '/donateHistory',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'DonateHistory',
        component: require('@/views/frontend/account/DonateHistory').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'ประงัติการบริจาค'
    }
  },
  {
    path: '/projectDetail',
    component: FrontendLayout,
    children:[
      {
        path: 'ProjectDetail/:id',
        name: 'ProjectDetail',
        component: require('@/views/frontend/donate/ProjectDetail').default,
        // beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'รายละเอียดโครงการ'
    }
  },
  {
    path: '/bankingTransaction',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'BankingTransaction',
        component: require('@/views/frontend/cart/BankingTransaction').default,
        beforeEach: authGuard
      }
    ],
    meta:{
      title: 'แก้ไขข้อมูลองค์กร'
    }
  },
  {
    path: '/cart',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'Cart',
        component: require('@/views/frontend/cart/Cart').default,
        beforeEach: authGuard
      }
    ],
    meta:{
      title: 'ตะกร้า'
    }
  },
  {
    path: '/organizationProfile',
    component: FrontendLayout,
    children:[
      {
        path: 'OrganizationProfile/:id',
        name: 'OrganizationProfile',
        component: require('@/views/frontend/account/OrganizationProfile').default,
        beforeEach: authGuard
      }
    ],
    meta:{
      title: 'แก้ไขข้อมูลองค์กร'
    }
  },
  {
    path: '/createOrganization',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'CreateOrganization',
        component: require('@/views/frontend/account/CreateOrganization').default,
        beforeEach: authGuard
      }
    ],
    meta:{
      title: 'เพิ่มลองค์กร'
    }
  },
  {
    path: '/oganizationManagement',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'OrganizationManagement',
        component: require('@/views/frontend/account/OrganizationManagement').default,
        beforeEach: authGuard
      }
    ],
    meta:{
      title: 'จัดการข้อมูลองค์กร'
    }
  },
  {
    path: '/accountSetting',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'AccountSetting',
        component: require('@/views/frontend/account/AccountSetting').default,
        beforeEach: authGuard
      }
    ],
    meta:{
      title: 'ตั้งค่าบัญชี'
    }
  },
  {
    path: '/profile',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Profile',
        component: require('@/views/frontend/account/Profile').default,
        beforeEach: authGuard
      }
    ],
    meta: {
      title: 'โปรไฟล์'
    }
  },
  {
    path: '/donateHistories',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'DonateHistories',
        component: require('@/views/frontend/donate/Histories').default,
        beforeEach: authGuard
      }
    ],
    meta:{
      title: 'ประวัติบริจาค'
    }
  },
  {
    path: '/donateCheckout/:id',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'DonateCheckout',
        component: require('@/views/frontend/donate/DonateCheckout').default
      },
    ],
    meta:{
      title: 'ยืนยันการบริจาค'
    }
  },
  {
    path: '/projectDonate/:id',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'ProjectDonate',
        component: require('@/views/frontend/donate/ProjectDonate').default,
      }
    ],
    meta: {
      title: 'บริจาค'
    }
  },
  {
    path: '/riba',
    component: FrontendLayout,
    children:[
      {
        path: '',
        name: 'Riba',
        component: require('@/views/frontend/riba/Index').default,
      }
    ],
    meta: {
      title: 'สะสางดอกเบี้ย'
    }
  },
  {
    path: '/resetPassword',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: "ResetPassword",
        component: require('@/views/frontend/account/ResetPassword').default,
        beforeEach: authGuard
      }
    ],
    meta:{
      title: 'รีเซ็ตรหัสผ่าน'
    }
  },
  {
    path: '/forgotPassword',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: "ForgotPassword",
        component: require('@/views/frontend/account/ForgotPassword').default,
        beforeEach: authGuard
      }
    ],
    meta:{
      title: 'ลืมรหัสผ่าน'
    }
  },
  {
    path: '/emailVerification',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'EmailVerification',
        component: require('@/views/frontend/account/EmailVerification').default,
        beforeEach: authGuard,
      }
    ],
    meta: {
      title: 'ยืนยันอีเมล'
    }
  },
  {
    path: '/cleanupHistory',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'CleanupHistory',
        component: require('@/views/frontend/account/CleanupHistory').default,
        beforeEnter: authGuard,
      }
    ],
    meta: {
      title: 'ประวัติการสะสาง',
      roles: ['$2y$10$nOt5dwhzLifPrx.GYk5V7eANvPiiTJdPC9j5gLoqIwE38TY7wJsbO']
    }
  },
  {
    path: '/account',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Account',
        component: require('@/views/frontend/account/Account').default,
        beforeEnter: authGuard,
      }
    ],
    meta:{
      title: 'บัญชี',
      // :permissions ["member-verified"],
      roles: ['$2y$10$nOt5dwhzLifPrx.GYk5V7eANvPiiTJdPC9j5gLoqIwE38TY7wJsbO'],
      beforeEnter: authGuard,
      // requiresAuth: true
    }
  },
  {
    path: '/contactMe',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'ContactMe',
        component: require('@/views/frontend/Contact').default
      }
    ],
    meta:{
      title: 'ติดต่อเรา',
      // description: 'ยืนยันการโอนเงิน'  
    }
  },
  {
    path: '/article',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Article',
        component: require('@/views/frontend/Article').default
      }
    ],
    meta:{
      title: 'วีดีโอความรู้',
      // description: 'ยืนยันการโอนเงิน'  
    }
  },
  {
    path: '/vdo',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Vdo',
        component: require('@/views/frontend/Vdo').default
      }
    ],
    meta:{
      title: 'การเงินในอิสลาม',
      // description: 'ยืนยันการโอนเงิน'  
    }
  },
  {
    path: '/literacy',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Literacy',
        component: require('@/views/frontend/Literacy').default
      }
    ],
    meta:{
      title: 'บริจาค',
      // description: 'ยืนยันการโอนเงิน'  
    }
  },
  {
    path: '/openData',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'OpenData',
        component: require('@/views/frontend/OpenData').default
      }
    ],
    meta:{
      title: 'Open data',
      // description: 'ยืนยันการโอนเงิน'  
    }
  },
  {
    path: '/donate',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Donate',
        component: require('@/views/frontend/donate/Donate').default
        // component: require('@/views/frontend/Donate').default
      }
    ],
    meta:{
      title: 'บริจาค',
      // description: 'ยืนยันการโอนเงิน'  
    }
  },
  {
    path: '/projects',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Projects',
        component: require('@/views/frontend/Project').default
      }
    ],
    meta:{
      title: 'โปรเจค',
      // description: 'ยืนยันการโอนเงิน'  
    }
  },
  {
    path: '/about',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'About',
        component: require('@/views/frontend/About').default
      }
    ],
    meta:{
      title: 'เกี่ยวกับเรา',
      // description: 'ยืนยันการโอนเงิน'  
    }
  },
  {
    path: '/cleanupConfirming',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'CleanupConfirming',
        component: require('@/views/frontend/riba/CleanupConfirming').default,  
      }
    ],
    meta: {
      title: 'ยืนยันการโอนเงิน',
      description: 'ยืนยันการโอนเงิน'
    }
  },
  {
    path: '/qrCleanup',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'QrCleanup',
        component: require('@/views/frontend/riba/Qrpayment').default,  
      }
    ],
    meta: {
      title: 'โอนเงินด้วย qr code',
      description: 'โอนเงินด้วย qr code'
    }
  },
  {
    path: '/cleanupDetail',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'CleanupDetail',
        component: require('@/views/frontend/CleanupDetail').default,
      },
    ],
    meta: {
      title: 'ยืนยันสะสางดอกเบี้ย',
      description: 'ยืนยันสะสางดอกเบี้ย'
    }
  },
  // {
  //   path: '/cleanupFor/:type',
  //   component: FrontendLayout,
  //   children: [
  //     {
  //       path: '',
  //       name: 'CleanupFor',
  //       component: require('@/views/frontend/CleanupFor').default,
  //     },
  //   ],
  //   meta: {
  //     title: 'สะสางดอกเบี้ย',
  //     description: 'สะสางดอกเบี้ย'
  //   }
  // },
  {
    path: '/cleanupType',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'CleanupType',
        component: require('@/views/frontend/CleanupType').default,
      },
    ],
    meta: {
      title: 'เลือกรูปแบบการสะสางดอกเบี้ย',
      description: 'เลือกรูปแบบการสะสางดอกเบี้ย'
    }
  },
  {
    path: '/cleanup',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Cleanup',
        component: require('@/views/frontend/Cleanup').default,
      },
    ],
    meta: {
      title: 'สะสางดอกเบี้ย',
      description: 'สะสางดอกเบี้ย'
    }
  },
  {
    path: '/',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home,
        // beforeEnter: authGuard
      }
    ],
    meta:{
      title: "หน้าหลัก",
      // description: "หน้าหลักระบบคงคลังสินค้า"
    }
  },
  {
    path: '/logout',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Logout',
        component: require('@/views/frontend/Logout').default,
      }
    ],
    meta: {
      title: 'ออกจากระบบ',
      description: 'ออกจากระบบ',
    }
  },
  {
    path: '/login',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Login',
        component: require('@/views/frontend/Login').default,
      }
    ],
    meta: {
      title: 'เข้าสู่ระบบ',
      description: 'เข้าสู่ระบบ',
      requiresVisitor: true
    }
  },
  {
    path: '/register',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'Register',
        component: Register,
      }
    ],
    meta: {
      title: 'สมัครสมาชิก',
      description: 'สมัครสมาชิก',
      requiresVisitor: true
    }
  },
  
  // backend
  {
    path: '/loginAdmin',
    component: FrontendLayout,
    children: [
      {
        path: '',
        name: 'LoginAdmin',
        component: Login,
      }
    ],
    meta:{
      title: "เกี่ยวกับเรา",
      requiresVisitor: true
      // description: "หน้าหลักระบบคงคลังสินค้า"
    }
  },
  //errir 404
  {
    path: "/:catchAll(.*)",
    // name: 'NotFound',
    component: NotFound,
    meta:{
      title: "ไม่พบหน้านี้",
      description: "หน้าหลักระบบคงคลังสินค้า"
    }
  },
  {
    path: "/notfound",
    name: 'NotFound',
    component: NotFound,
    meta:{
      title: "ไม่พบหน้านี้",
      description: "หน้าหลักระบบคงคลังสินค้า"
    }
  },

  /** backend route */
  {
    path: '/dashboard',
    component: BackendLayout,
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: require('@/views/backend/Dashboard.vue').default,
        beforeEnter: authGuard
      }
    ],
    meta:{
      title: "Dashboard",
      description: "หน้าหลัก",
      roles: ['$2y$10$qeKVPAufM9D/Q6S4iiOTnut9s9I6XWmHTiVAmbUfztcDg6/ddjVjm']//admin
    }
  },

  {
    path: '/dashboard',
    component: BackendLayout,
    children: [
      {
        path: 'project-Category',
        name: 'ProjectCategory',
        component: require('@/views/backend/project/Category').default,
        beforeEnter: authGuard
      }
    ],
    meta:{
      title: 'หมวดหมู่โครงการ',
      roles: ['$2y$10$qeKVPAufM9D/Q6S4iiOTnut9s9I6XWmHTiVAmbUfztcDg6/ddjVjm']//admin
    }
  },

  //user management
  //role
  {
    path: '/dashboard',
    component: BackendLayout,
    children: [
      {
        path: 'role',
        name: 'Role',
        component: require('@/views/backend/userManagement/Role').default,
        beforeEnter: authGuard,
      }
    ],
    meta: {
      title: 'บทบาท',
      roles: ['$2y$10$qeKVPAufM9D/Q6S4iiOTnut9s9I6XWmHTiVAmbUfztcDg6/ddjVjm']//admin
    }
  },
  //permission
  {
    path: '/dashboard',
    component: BackendLayout,
    children: [
      {
        path: 'permission',
        name: 'Permission',
        component: require('@/views/backend/userManagement/Permission').default,
        beforeEnter: authGuard,
      }
    ],
    meta: {
      title: 'สิทธิ์',
      roles: ['$2y$10$qeKVPAufM9D/Q6S4iiOTnut9s9I6XWmHTiVAmbUfztcDg6/ddjVjm']//admin
    }
  },
  //member
  {
    path: '/dashboard',
    component: BackendLayout,
    children: [
      {
        path: 'member',
        name: 'Member',
        component: require('@/views/backend/userManagement/Member').default,
        beforeEnter: authGuard
      }
    ],
    meta:{
      title: 'สมาชิก',
      roles: ['$2y$10$qeKVPAufM9D/Q6S4iiOTnut9s9I6XWmHTiVAmbUfztcDg6/ddjVjm']//admin
    }
  },
  {
    path: '/dashboard',
    component: BackendLayout,
    children: [
      {
        path: 'memberRole/:id',
        name: 'MemberRole',
        component: require('@/views/backend/userManagement/MemberRole').default,
        beforeEnter: authGuard
      }
    ],
    meta:{
      title: 'สมาชิก',
      roles: ['$2y$10$qeKVPAufM9D/Q6S4iiOTnut9s9I6XWmHTiVAmbUfztcDg6/ddjVjm']//admin
    }
  },
  //roleHasPermission
  {
    path: '/dashboard',
    component: BackendLayout,
    children: [
      {
        path: 'roleHasPermission/:id',
        name: 'RoleHasPermission',
        component: require('@/views/backend/userManagement/RoleHasPermission').default,
        // props: true,
        beforeEnter: authGuard
      }
    ],
    meta:{
      title: 'จัดการบทบาท'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if(!store.getters['authMember/logedIn']){
      router.push({
        name: 'Login' //ถ้าไม่มีสิทธ์ให้แสดงว่าไม่พบหน้าที่ต้องการ
      })
    } else {
      next()
    }
  }else if(to.matched.some(record => record.meta.requiresVisitor)) {
    if(store.getters['authMember/logedIn']){
      router.push({
        name: 'Riba'//ถ้าไม่มีสิทธ์ให้แสดงว่าไม่พบหน้าที่ต้องการ
      })
    }else{
      next()
    }
  }else{
    next()
  }
})


export default router
