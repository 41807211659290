<template>
    <footer class="text-white bg-gray-900">
        <div class="container pt-20 mx-auto">
            <div class="flex flex-wrap">
                <div class="w-full p-4 xl:mr-auto xl:w-4/12">
                    <img src="@/assets/img/logoCuCi.png" class="w-20 mb-6" alt="smart logo" width="" height=""/>
                    <p class="mb-4 text-sm">มูลนิธิจูจีสร้างสรรค์สังคม</p>
                    <div class="mb-4">
                        <!-- <a href="#" class="hover:text-indigo-200">087-837-8335</a>
                        <br>
                        <a href="#" class="hover:text-indigo-200">cuci.pbf@gmail.com</a> -->
                        <p>CuCi Social Creation Foundation</p>
            <p>เลขที่  15 ซอยวสันต์พัฒนา 1 ถนนขวัญเมือง ตำบลสะเตง อำเภอเมืองยะลา จังหวัดยะลา 95000</p>
            <p>Email : cuci.pbf@gmail.com </p>
            <p>เบอร์ 087-837-8335 </p><br>
                    </div>
                    <div>
                        <a href="https://web.facebook.com/cuci.pbf" class="mr-2 text-xl hover:text-indigo-200"><i class="fa-facebook-square fab"></i></a>
                        <a href="https://lin.ee/1y0IPUU" class="mr-2 text-xl hover:text-indigo-200"><i class="fa-line fab"></i></a>
                        <a href="https://www.messenger.com/t/686171485149705/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=1441792&recurring_notification=0" class="mr-2 text-xl hover:text-indigo-200"><i class="fab fa-facebook-messenger"></i></a>
                    </div>
                </div>
                <div class="w-full p-4 xl:w-2/12 sm:w-4/12">
                    <h4 class="mb-8 text-lg font-medium text-gray-400 uppercase">About</h4>
                    <ul>
                        <li class="mb-4">
                            <router-link to="about">
                                <a href="#" class="hover:text-indigo-200">เกี่ยวกับเรา</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <!-- <router-link to="about"> -->
                                <a href="#" class="hover:text-indigo-200">คณะกรรมการ</a>
                            <!-- </router-link> -->
                        </li>
                        <li class="mb-4">
                            <router-link to="projects">
                                <a href="#" class="hover:text-indigo-200">สิ่งที่เราทำ</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <router-link to="/contactMe">
                                <a href="#" class="hover:text-indigo-200">ติดต่อเรา</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <router-link to="/privacy">
                                <a href="#" class="hover:text-indigo-200">นโยบายคุ้มครองข้อมูลส่วนบุคคล</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <router-link to="/refund">
                                <a href="#" class="hover:text-indigo-200">นโยบายการคืนเงิน</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <router-link to="/amlPolicy">
                                <a href="#" class="hover:text-indigo-200">นโยบายว่าด้วยการป้องกันการฟอกเงินฯ</a>
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="w-full p-4 xl:w-2/12 sm:w-4/12">
                    <h4 class="mb-8 text-lg font-medium text-gray-400 uppercase">Services</h4>
                    <ul>
                        <li class="mb-4">
                            <router-link to="riba">
                                <a href="#" class="hover:text-indigo-200">สะสางดอกเบี้ย</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <router-link to="/donate">
                                <a href="#" class="hover:text-indigo-200">บริจาค</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <router-link to="/Literacy">
                                <a href="#" class="hover:text-indigo-200">องค์ความรู้</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <!-- <router-link to="/donate"> -->
                                <!-- <a href="#" class="hover:text-indigo-200">ยื่นโครงการ</a> -->
                            <!-- </router-link> -->
                        </li>
                        
                    </ul>
                </div>
                <div class="w-full p-4 xl:w-2/12 sm:w-4/12">
                    <h4 class="mb-8 text-lg font-medium text-gray-400 uppercase">Other</h4>
                    <ul>
                        <li class="mb-4">
                            <router-link to="/opendata">
                                <a href="#" class="hover:text-indigo-200">Open Data</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <router-link to="/login">
                                <a href="#" class="hover:text-indigo-200">เข้าสู่ระบบ</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <router-link to="/register">
                                <a href="#" class="hover:text-indigo-200">สมัครสมชิก</a>
                            </router-link>
                        </li>
                        <li class="mb-4">
                            <!-- <router-link to="contactMe"> -->
                                <!-- <a href="#" class="hover:text-indigo-200">แจ้งปัญการใช้งาน</a> -->
                            <!-- </router-link> -->
                        </li>
                    </ul>
                </div>
            </div>
            <div class="p-4 text-center">
                <hr class="mb-4 opacity-25">
                <p class="text-sm">Copyright © CUCI 2022</p>
            </div>
        </div> 
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>