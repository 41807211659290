<template>
    <section>
        <div class="w-full px-4 pt-40 mx-auto lg:mx-auto ">
            <div class="mx-4 lg:mx-40">
                <h2 class="mb-2 text-4xl font-semibold leading-tight text-center text-gray-800 capitalize">
                    นโยบายว่าด้วยการป้องกันการฟอกเงินและป้องกันมิให้มีสนับสนุนทางการเงินแก่การก่อการร้ายและการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง
                </h2>
                
                <p>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เพื่อให้เป็นไปตามที่ได้บัญญัติไว้ในพระราชบัญญัติป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้าย และการแพร่ขยายอาวุธที่มีอานุภาพทําลายล้างสูง และพระราชบัญญัติป้องกันและปราบปรามการฟอกเงินแห่งราชอาณาจักรไทย และเพื่อให้มูลนิธิจูจีสร้างสรรค์สังคม (ซึ่งต่อไปนี้จะเรียกว่า “มูลนิธิฯ”) ดำเนินธุรกิจอย่างถูกต้องเหมาะสม เพื่อลดความเสี่ยงในการดำเนินธุรกิจ ทางมูลนิธิฯ จึงได้มีการกำหนดนโยบายว่าด้วยการป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้าย การแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง และการฟอกเงิน (ซึ่งต่อไปนี้จะเรียกว่า “นโยบายนี้”) ขึ้น
                </p>

                <h3 class="mt-5 text-xl font-semibold">1. การควบคุมดูแลสินค้าและบริการ</h3>
                <ul>
                    <li class="mt-3">1.1. สินค้าและบริการของมูลนิธิฯ</li>
                    <ul>
                        <li>
                            <p>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในการควบคุมดูแลความเสี่ยงที่เกี่ยวกับการสนับสนุนทางการเงินด้านการฟอกเงินและการก่อการร้ายในส่วนของสินค้าและบริการ หรือการใช้บริการ หรือที่มาของธุรกรรมในปัจจุบันของทางมูลนิธิฯ มูลนิธิฯ จะพิจารณาความเสี่ยงในสินค้าและบริการแต่ละประเภท หรือการใช้บริการ หรือที่มาของธุรกรรมไปพร้อมกับความเสี่ยงอื่นๆ ของผู้ใช้งานระบบโดยรวมด้วย
                            </p>
                            <p>
                                มูลนิธิฯ มีการนำเสนอสินค้าและบริการ หรือมีการนำเทคโนโลยีๆ ดังต่อไปนี้เข้ามาใช้มูลนิธิฯ จะดำเนินมาตราการเพื่อลดความเสี่ยงที่เกี่ยวกับการสนับสนุนทางการเงินแก่การก่อการร้ายและการฟอกเงิน
                            </p>
                            <ul>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) ระบบเทคโนโลยีสารสนเทศและเครื่องมือต่างๆ ที่มูลนิธิฯ นำมาใช้ในปัจจุบัน ซึ่งได้มีการนำระบบดิจิทัลที่เกี่ยวกับเส้นทางในการให้บริการด้านการเงินหรือการนำเสนอสินค้า การพบปะ ติดต่อ เข้าหา การสร้างสัมพันธ์ทางธุรกรรมกับกลุ่มผู้ใช้งานระบบ การทำธุรกรรม หรือการสานต่อความสัมพันธ์ทางธุรกรรมมาใช้ หรือมีข้อมูลที่เกี่ยวกับระบบดิจิทัลรวมอยู่ด้วย</li>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) ระบบเทคโนโลยีสารสนเทศและเครื่องมือต่างๆ ที่มูลนิธิฯ ใช้เป็นเส้นทางในการให้บริการ</li>
                                <li>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3) สินค้าด้านการเงินที่ได้มีการพัฒนาขึ้น และจะนำมาใช้ หรือนำมาจำหน่ายในอนาคต ซึ่งได้มีการนำระบบดิจิทัลที่เกี่ยวกับเส้นทางในการให้บริการด้านการเงินหรือการนำเสนอสินค้า การพบปะ ติดต่อ เข้าหา การสร้างสัมพันธ์ทางธุรกรรมกับกลุ่มผู้ใช้งานระบบ การทำธุรกรรม หรือการสานต่อความสัมพันธ์ทางธุรกรรมมาใช้ หรือมีข้อมูลที่เกี่ยวกับระบบดิจิทัลรวมอยู่ด้วย</p>
                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีที่การใช้ระบบเทคโนโลยีสารสนเทศหรือเครื่องมือมีความเสี่ยงที่เกี่ยวกับ 1) การสนับสนุนทางการเงินแก่การก่อการร้ายหรือการฟอกเงิน 2) การละเมิดกฎหมายว่าด้วยการป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้าย และการแพร่ขยายอาวุธที่มีอานุภาพทําลายล้างสูง หรือกฎหมายที่เกี่ยวข้อง หรือ 3) มีความเสี่ยงทั้งข้อ 1) และ 2) พนักงานและบุคคลที่เกี่ยวข้องจะสามารถรับมือกับความเสี่ยงเหล่านั้นได้ จากมาตรการลดความเสี่ยงและวิธีการประเมินสถานะในการดำเนินการตามมาตรการ ในกรณีที่ไม่สามารถลดความเสี่ยงดังกล่าวได้ พนักงานและบุคคลที่เกี่ยวข้องจะระงับการใช้งานหรือระงับการอนุญาตให้ใช้งานระบบเทคโนโลยีสารสนเทศหรือเครื่องมือนั้น</p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </ul>

                <h3 class="mt-5 text-xl font-semibold">2. การกำหนดนโยบายและระเบียบวิธีการสำหรับการประเมินและบริหารความเสี่ยงภายในองค์กรด้านการฟอกเงินและการก่อการร้ายและการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง</h3>
                <ul>
                    <li class="mt-3">2.1. จัดทำนโยบายและและระเบียบวิธีการสำหรับการประเมินและบริหารความเสี่ยงภายในองค์กรเป็นลายลักษณ์อักษร โดยมีสาระสำคัญเป็นไปตามประกาศสำนักงานป้องกันและปราบปรามการฟอกเงิน เรื่อง แนวทางในการกำหนดนโยบายและระเบียบวิธีการสำหรับการประเมิน บริหาร และบรรเทาความเสี่ยงด้านการฟอกเงิน หรือการสนับสนุนทางการเงินแก่การก่อการร้าย หรือการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูงภายในองค์กรของสถาบันการเงินและผู้ประกอบอาชีพตามมาตรา 16 ดังนี้</li>
                    <ul>
                        <li>
                            <ul>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) กำหนดมาตรการที่เหมาะสมในการประเมินความเสี่ยงภายในองค์กร</li>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) นำปัจจัยความเสี่ยงตามที่กฎหมายกำหนดมาประเมินความเสี่ยง</li>
                                <ul>
                                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; o	ความเสี่ยงเกี่ยวกับผู้ใช้งานระบบทั้งหมดขององค์กร</li>
                                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; o	ความเสี่ยงเกี่ยวกับพื้นที่หรือประเทศ โดยให้พิจารณาสถานที่ตั้ง สาขา พื้นที่ให้บริการหรือแหล่งที่มาของรายได้ขององค์กร ซึ่งอยู่ในพื้นที่หรือประเทศตามที่สำนักงาน ปปง. ประกาศกำหนด</li>
                                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; o	ความเสี่ยงเกี่ยวกับผลิตภัณฑ์หรือบริการ ให้พิจารณาจากลักษณะ ดังต่อไปนี้
                                        <br>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ก) ผลิตภัณฑ์หรือบริการที่สามารถให้ รับ หรือเปลี่ยนเป็นเงินสดได้ ซึ่งความเสี่ยงจะเพิ่มขึ้นตามจำนวนเงินสดที่ผลิตภัณฑ์หรือบริการนั้นสามารถรองรับได้<br>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ข) ผลิตภัณฑ์หรือบริการที่สามารถโอนหรือเปลี่ยนมือให้แก่บุคคลอื่นได้ ซึ่งความเสี่ยงจะเพิ่มขึ้นตามมูลค่า ความถี่ ความรวดเร็ว หรือความสะดวก ในการโอนหรือเปลี่ยนมือ<br>
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ค) ผลิตภัณฑ์หรือบริการที่สามารถใช้หรือนำไปใช้ได้ในต่างประเทศ ซึ่งความเสี่ยงจะเพิ่มขึ้นหากผลิตภัณฑ์หรือบริการนั้นสามารถใช้ข้ามประเทศได้<br>
                                    </li>
                                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; o ความเสี่ยงเกี่ยวกับช่องทางในการให้บริการ โดยให้คำนึงถึงการให้บริการแบบไม่พบหน้าผู้ใช้งานระบบ (Non-face-to-face) และแบบพบหน้าผู้ใช้งานระบบ (face-to-face)</li>
                                </ul>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3) นำผลการประเมินความเสี่ยงตามรายงานการประเมินความเสี่ยงระดับชาติมาพิจารณาประกอบ</li>
                            </ul>
                        </li>
                    </ul>
                    <li class="mt-3">2.2 นโยบายและและระเบียบวิธีการสำหรับการประเมินและบริหารความเสี่ยงภายในองค์กรจะต้องผ่านความเห็นชอบและได้รับอนุมัติ จากผู้บริหารระดับสูงหรือคณะกรรมการ</li>
                    <li class="mt-3">2.3 จัดทำรายงานการประเมินและบริหารความเสี่ยงภายในองค์กร โดยมีมาตรการบรรเทาความเสี่ยงให้สอดคล้องกับผลการประเมินความเสี่ยง</li>
                    <li class="mt-3">2.4 ปรับปรุงผลการประเมินความเสี่ยงภายในองค์กรให้เป็นปัจจุบัน โดยกำหนดรอบระยะเวลาในการประเมินความเสี่ยงภายในองค์กรให้ชัดเจนเป็นลายลักษณ์อักษร</li>
                </ul>

                <h3 class="mt-5 text-xl font-semibold">3. การจัดให้ผู้ใช้งานระบบแสดงตน การตรวจสอบเพื่อทราบข้อเท็จจริงเกี่ยวกับผู้ใช้งานระบบ และการปฏิเสธการรับผู้ใช้งานระบบ</h3>
                <ul>
                    <li class="mt-3">3.1 การจัดให้ผู้ใช้งานระบบแสดงตน</li>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ทางมูลนิธิฯ จะปฏิบัติตามเงื่อนไขและวิธีการที่เกี่ยวกับการแสดงตัวตนของผู้ใช้งานระบบตามที่กำหนดไว้ในคำสั่ง ข้อบังคับและประกาศ โดยจะจัดให้ผู้ใช้งานระบบแสดงตนด้วยการให้ผู้ใช้งานระบบเปิดเผยข้อมูลของผู้ใช้งานระบบ</p>
                    
                    <li class="mt-3">3.2 การตรวจสอบเพื่อทราบข้อเท็จจริงเกี่ยวกับผู้ใช้งานระบบ</li>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ทางมูลนิธิฯ จะปฏิบัติตามเงื่อนไขและวิธีการที่เกี่ยวกับการตรวจสอบเพื่อทราบข้อเท็จจริงเกี่ยวกับผู้ใช้งานระบบตามที่กำหนดไว้ในคำสั่ง ข้อบังคับ และประกาศ</p>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(1) ในการตรวจสอบเพื่อทราบข้อเท็จจริงเกี่ยวกับผู้ใช้งานระบบ มูลนิธิฯ จะปฏิบัติดังต่อไปนี้</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	ระบุตัวตนของผู้ใช้งานระบบ และตรวจสอบตัวตนของผู้ใช้งานระบบ โดยใช้เอกสาร ข้อมูลที่ได้รับจากผู้ใช้งานระบบหรือจากแหล่งข้อมูลอื่นที่น่าเชื่อถือ</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	นำข้อมูลของผู้ใช้งานระบบไปตรวจเทียบกับ “รายชื่อบุคคลที่ถูกกำหนด” ตามที่สำนักงานป้องกันและปราบปรามการฟอกเงิน (ซึ่งต่อไปนี้จะเรียกว่า “ปปง.”) ได้ประกาศไว้</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	ตรวจสอบว่าผู้ใช้งานระบบเป็นบุคคลที่มีสถานภาพทางการเมือง หรือเป็นสมาชิกในครอบครัวหรือผู้ใกล้ชิดบุคคลที่มีสถานภาพทางการเมืองหรือไม่</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	ตราบใดที่ยังมีการทำธุรกรรมกับผู้ใช้งานระบบ ทางมูลนิธิฯ จะทำการตรวจสอบความเคลื่อนไหวในการทำธุรกรรมของผู้ใช้งานระบบว่ายังสอดคล้องกับวัตถุประสงค์ในการสร้างความสัมพันธ์ ระดับความเสี่ยงของผู้ใช้งานระบบที่ได้ประเมินไว้และข้อมูลเกี่ยวกับแหล่งที่มาของรายได้ และจะจัดทำข้อมูลอื่นๆของผู้ใช้งานระบบเพื่อให้เป็นข้อมูลล่าสุดอยู่ตลอดเวลา</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	ในการตรวจสอบระดับความเสี่ยงของผู้ใช้งานระบบ จะตรวจสอบในส่วนขององค์ประกอบที่เกี่ยวกับประเทศหรือเขตพื้นที่ไปพร้อมกับองค์ประกอบอื่นๆ</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(2) สำหรับผู้ใช้งานระบบที่มีระดับความเสี่ยงสูง จะดำเนินการตรวจสอบเพื่อทราบข้อเท็จจริงเกี่ยวกับผู้ใช้งานระบบในระดับเข้มข้น</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(3) ในการตรวจสอบเพื่อทราบข้อเท็จจริงเกี่ยวกับผู้ใช้งานระบบ จะดำเนินการบริหารความเสี่ยง โดยการพิจารณาตามผลการตรวจสอบ และอ้างอิงตามรายงานการตรวจสอบความเสี่ยง</li>

                    <li class="mt-3">3.3 การปฏิเสธผู้ใช้งานระบบ</li>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีตามข้อใดข้อหนึ่งดังต่อไปนี้ มูลนิธิฯ จะปฏิเสธการทำธุรกรรมกับผู้ใช้งานระบบ</p>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	กรณีที่ผู้ใช้งานระบบเป็นผู้ที่มีชื่ออยู่ในรายชื่อบุคคลที่ถูกกำหนดตามประกาศของ ปปง.</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	กรณีที่ไม่สามารถตรวจสอบเอกสารแสดงตนของผู้ใช้งานระบบได้ หรือกรณีที่ข้อมูลที่ได้รับมาไม่เพียงพอ</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	กรณีที่ผู้ใช้งานระบบใช้นามแฝงหรือชื่อปลอม กรณีที่ให้ข้อมูลเท็จ กรณีที่แสดงเอกสารปลอม หรือกรณีที่ไม่สามารถตรวจสอบได้ว่าผู้ใช้งานระบบเป็นบุคคลคนเดียวกันกับเอกสารการแสดงตัวตน</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	กรณีที่ระดับความเสี่ยงของผู้ใช้งานระบบสูงถึงขนาดที่อาจจะมีโอกาสที่มูลนิธิฯ จะถูกนำใช้ไปในการสนับสนุนทางการเงินแก่การก่อการร้าย การแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง หรือการฟอกเงินได้</li>

                </ul>

                <h3 class="mt-5 text-xl font-semibold">4. การทำธุรกรรมของผู้ใช้งานระบบ</h3>
                <ul>
                    <li class="mt-3">4.1 การตรวจสอบเกี่ยวกับการทำธุรกรรมของผู้ใช้งานระบบ</li>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;แม้ข้อมูลของผู้ใช้งานระบบจะไม่ตรงกับรายชื่อบุคคลที่ถูกกำหนดตามประกาศของ ปปง. มูลนิธิฯ ขอสงวนสิทธิในการตรวจสอบข้อมูลโดยนำเงื่อนไขและวิธีการที่เกี่ยวกับการแสดงตัวตนของผู้ใช้งานระบบตามที่กำหนดไว้ในคำสั่ง ข้อบังคับ และประกาศมาใช้อ้างอิง เพื่อตรวจสอบว่าผู้ใช้งานระบบมีส่วนเกี่ยวข้องหรืออาจจะมีส่วนเกี่ยวข้องกับการสนับสนุนทางการเงินแก่การก่อการร้ายหรือไม่</p>
                    <li>4.2 การรายงานไปยัง ปปง.</li>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในกรณีตามข้อใดข้อหนึ่งดังต่อไปนี้ มูลนิธิฯ จะถือว่าเป็นธุรกรรมที่มีเหตุอันควรสงสัย และจะรายงานไปยัง ปปง.</p>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	กรณีมีเหตุผลเพียงพอที่ทำให้เชื่อได้ว่า การทำธุรกรรมกับผู้ใช้งานระบบนั้นมีส่วนเกี่ยวข้องหรืออาจจะเกี่ยวข้องกับการสนับสนุนทางการเงินแก่การก่อการร้าย</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	กรณีที่ผู้เกี่ยวข้องโดยตรงเป็นผู้ที่มีชื่ออยู่ในรายชื่อบุคคลที่ถูกกำหนดตามประกาศของ ปปง.</li>
                    <li>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;o	กรณีมีเหตุผลเพียงพอที่ทำให้เชื่อได้ว่า การทำธุรกรรมกับผู้ใช้งานระบบนั้นเป็นการหรืออาจจะเป็นการทำธุรกรรมเพื่อผู้ที่มีชื่ออยู่ในรายชื่อบุคคลที่ถูกกำหนดตามประกาศของ ปปง.</li>

                </ul>

                <h3 class="mt-5 text-xl font-semibold">5. การบริหารความเสี่ยงที่เกี่ยวกับการสนับสนุนทางการเงินแก่การก่อการร้าย การแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง และการฟอกเงิน</h3>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;มูลนิธิฯ จะปฏิบัติตาม “พระราชบัญญัติป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้าย และการแพร่ขยายอาวุธที่มีอานุภาพทําลายล้างสูง และพระราชบัญญัติป้องกันและปราบปรามการฟอกเงิน” และคำสั่ง ข้อบังคับ คำประกาศ และสิ่งอื่นๆ ตามที่ ปปง. กำหนด และจะพยายามป้องกันมิให้มูลนิธิฯ ถูกนำไปใช้เป็นช่องทางในการแพร่ขยายอาวุธที่มีอานุภาพทําลายล้างสูง การสนับสนุนทางการเงินแก่การก่อการร้าย และการฟอกเงิน</p>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ในการดำเนินการบริหารความเสี่ยงที่เกี่ยวกับการแพร่ขยายอาวุธที่มีอานุภาพทําลายล้างสูง การสนับสนุนทางการเงินแก่การก่อการร้าย และการฟอกเงิน ทางมูลนิธิฯ ได้จัดตั้งระเบียบขึ้นตามนโยบายนี้ และจะดำเนินการดังต่อไปนี้</p>
                <ul>
                    <li>5.1 รับและตรวจสอบผู้ใช้งานระบบ โดยปฏิบัติตามกฎหมายที่เกี่ยวข้อง</li>
                    <li>5.2 ตรวจสอบข้อมูลของผู้ใช้งานระบบหรือผู้ใช้งานระบบเก่าที่มีชื่อตรงกับ “รายชื่อบุคคลที่ถูกกำหนด” และรายงานไปยัง ปปง.</li>
                    <li>5.3 ตัดการสร้างสัมพันธ์ที่เกี่ยวข้องกับการแพร่ขยายอาวุธที่มีอานุภาพทําลายล้างสูง การสนับสนุนทางการเงินแก่การก่อการร้าย และการฟอกเงิน และระงับการดำเนินการเกี่ยวกับธุรกรรมและทรัพย์สิน</li>
                    <li>5.4 รายงานธุรกรรมที่ใช้เงินสดและธุรกรรมที่มีเหตุอันควรสงสัยไปยัง ปปง.</li>
                </ul>

                <h3 class="mt-5 text-xl font-semibold">6. การดำเนินการเพื่อปฏิบัติตามนโยบายนี้</h3>
                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;กรรมการ พนักงาน และตัวแทนของมูลนิธิฯ ทั้งหมดจะต้องปฏิบัติตามระเบียบและนโยบายว่าด้วยการป้องกันการฟอกเงินและป้องกันมิให้มีสนับสนุนทางการเงินแก่การก่อการร้ายและการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูง เพื่อให้เป็นไปตามที่ได้กล่าวข้างต้น โดยมูลนิธิฯ จะดำเนินการดังต่อไปนี้ เพื่อให้ปฏิบัติงานได้อย่างมีประสิทธิภาพภายใต้ “พระราชบัญญัติป้องกันและปราบปรามการสนับสนุนทางการเงินแก่การก่อการร้าย และการแพร่ขยายอาวุธที่มีอานุภาพทําลายล้างสูง และพระราชบัญญัติป้องกันและปราบปรามการฟอกเงิน”</p>
                <ul>
                    <li>6.1 ทบทวนระเบียบและนโยบายว่าด้วยการป้องกันการฟอกเงินและป้องกันมิให้มีสนับสนุนทางการเงินแก่การก่อการร้ายและการแพร่ขยายอาวุธที่มีอานุภาพทำลายล้างสูงปีละ 1 ครั้ง หรือเมื่อมีการเปลี่ยนแปลงที่มีผลกระทบต่อการปฏิบัติงานของมูลนิธิ</li>
                    <li>6.2 จัดทำระเบียบเกี่ยวกับการควบคุมภายใน เพื่อให้ปฏิบัติงานได้อย่างเหมาะสมตามกฎหมาย</li>
                    <li>6.3 จัดตั้งคณะกรรมการป้องกันและปราบปรามการฟอกเงิน โดยมีประธานเป็นผู้รับผิดชอบการป้องกันและปราบปรามการฟอกเงิน</li>
                    <li>6.4 ตรวจสอบ “รายชื่อบุคคลที่ถูกกำหนด” เวลาว่าจ้างพนักงาน</li>
                    <li>6.5 จัดอบรมเกี่ยวกับระเบียบและนโยบายฉบับนี้ให้กับกรรมการ พนักงาน และผู้แทนมูลนิธิอย่างต่อเนื่อง</li>
                    <li>6.6 จัดตั้งแผนกตรวจสอบภายในที่เป็นอิสระ และตรวจสอบการปฏิบัติงาน</li>
                    <p class="mt-3">มูลนิธิฯ ถือว่านโยบายนี้เป็นนโยบายที่มีความสำคัญที่สุด และเป็นมติของที่ประชุมคณะกรรมการมูลนิธิ</p>
                </ul>

                <p class="mt-10">จัดทำขึ้นในวันที่ 10 เดือนธันวาคม พ.ศ. 2565</p>
                <p> ประธานมูลนิธิจูจีสร้างสรรค์สังคม</p>


                <p style="color: #999; font-style: italic;" class="mb-3">ปรับปรุงล่าสุด 1 ธันวาคม 2565</p>
            </div>   
        </div>
    </section>
</template> 

<script>
export default{
    mounted(){
        window.scrollTo({top: 0, behavior: 'smooth'});
    },
}
</script>