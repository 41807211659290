<template>
  
  <!-- slider -->
  <section>
    <div class="hidden w-full pt-24 mx-auto md:block">
      <div id="carouselExampleCaptions" class="relative carousel slide" data-bs-ride="carousel">
        <div class="absolute bottom-0 left-0 right-0 flex justify-center p-0 mb-4 carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="w-full overflow-hidden carousel-inner">
          <div class="float-left w-full carousel-item active">
            <img
              src="@/assets/img/bg.png"
              class="block w-full"
              alt="..."
            />
            <div class="absolute hidden text-center carousel-caption md:block">
              <h5 class="text-xl">CUCI</h5>
              <p>สร้างสรรค์สังคมที่ดีกว่าร่วมกัน</p>
            </div>
          </div>
          <div class="float-left w-full carousel-item">
            <img
              src="@/assets/img/riba_ban.png"
              class="block w-full"
              alt="..."
            />
            <div class="absolute hidden text-center carousel-caption md:block">
              <h5 class="text-xl">สะสางดอกเบี้ย</h5>
              <p>ทางเลือกเพื่อความสะอาดใจ</p>
            </div>
          </div>
          <div class="float-left w-full carousel-item">
            <img
              src="@/assets/img/derma_bg.png"
              class="block w-full"
              alt="..."
            />
            <div class="absolute hidden text-center carousel-caption md:block">
              <h5 class="text-xl">บริจาค</h5>
              <p>มอบโอกาสสู่การร่วมกันพํฒนาและสร้างสรรค์สังคมที่ดีกว่าไปร่วมกัน</p>
            </div>
          </div>
        </div>
        <button
          class="absolute top-0 bottom-0 left-0 flex items-center justify-center p-0 text-center border-0 carousel-control-prev hover:outline-none hover:no-underline focus:outline-none focus:no-underline"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
        >
          <span class="inline-block bg-no-repeat carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="absolute top-0 bottom-0 right-0 flex items-center justify-center p-0 text-center border-0 carousel-control-next hover:outline-none hover:no-underline focus:outline-none focus:no-underline"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
        >
          <span class="inline-block bg-no-repeat carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <div class="w-full pt-20 mx-auto md:hidden lg:hidden xl:hidden">
      <div class="container py-1 mx-auto">
        <div class="flex flex-wrap items-center ">
          <div class="order-last w-full p-4 lg:w-full lg:order-first">
            <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              <img src="@/assets/img/bg.png" alt="">
            </h2>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- \.slider -->

  <!-- // โครงการที่ผ่านมา -->
  <section class="bgcc">
    <div class="container pb-20 mx-auto text-center">

      <div class="w-full px-4 mb-4 lg:mx-auto lg:w-1/2">
        <h2
          class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize lg:pt-10 "
        > บริการของเรา
        </h2>
      </div>
      <div class="flex flex-wrap items-center mb-4">
        
        <div class="w-full p-4 lg:w-4/12 sm:w-12/12">
          <router-link
            to="/riba"
            class="block text-left"
          >
            <img
              src="@/assets/img/riba_2.png"
              class=""
            />
            
          </router-link>
          </div>

        

        

          <div class="w-full p-4 lg:w-4/12 sm:w-12/12">
          <router-link
            to="/donate"
            class="block text-left"
          >
            <img
              src="@/assets/img/derma_fund.png"
              class=""
            />
            <!-- <div class="">
              <h5 class="text-base font-semibold text-center text-gray-900">บริจาค</h5>
            </div> -->
          </router-link>
          
        </div>

        <div class="w-full p-4 lg:w-4/12 sm:w-12/12">
          <router-link
            to="/zakat"
            class="block text-left"
          >
            <img
              src="@/assets/img/zakat_fund.png"
              class=""
            />
            <!-- <div class="">
              <h5 class="text-base font-semibold text-center text-gray-900"><center>สะสางดอกเบี้ย</center></h5>
            </div> -->
          </router-link>
          </div>
        
        
      </div>
      <!-- <a href="#" class="inline-block px-6 py-2 text-white bg-green-600 border-2 border-green-600 rounded-md hover:text-green-600 hover:bg-green-200">ดูทั้งหมด</a> -->

    </div>

  
  </section>
  <!-- // โครงการที่กำลังดำเนินการ -->

  <!-- สะสางดอกเบี้ย -->
  <section class="bgcc">
    <div class="container py-1 mx-auto">
      <div class="flex flex-wrap items-center ">
        <div class="order-last w-full p-4 lg:w-full lg:order-first">
          <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
        ข้อมูลการสะสางดอกเบี้ย
    </h2>
    
    <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-3">
          <!-- Card จำนวนวัน-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-calendar-days"></i>      
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">เริ่มรับสะสางดอกเบี้ยตั้งแต่ 30 มิถุนายน 2562 </p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">จำนวนวันสะสม 1,274 วัน</p>
            </div>
        </div>
          <!-- Card ครั้งที่โอน-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-money-bill-transfer"></i>   
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">จำนวนการสะสางสะสม</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">36,300 ครั้ง</p>
            </div>
        </div>      
          <!-- Card จำนวนเงิน-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-sack-dollar"></i>
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">จำนวนเงิน </p>
                <p class="text-lg font-semibold text-white dark:text-gray-200"> 2,636,555.74 บาท</p>
            </div>
        </div>            
    </div>
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
        ข้อมูลโครงการสาธารณประโยชน์
    </h2>
    <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
          <!-- Card โครงการที่อนุมัติ-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              
              <i class="fa-solid fa-building"></i>
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">โครงการที่อนุมัติ</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">21 โครงการ <br />1,784,000 บาท</p>
            </div>
        </div>
          <!-- Card โครงการที่ดำเนินการเสร็จแล้ว-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-money-bill-transfer"></i>   
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">โครงการที่ดำเนินการเสร็จแล้ว</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">18 โครงการ <br />1,584,000 บาท</p>
            </div>
        </div>      
          <!-- Card โครงการที่กำลังดำเนินการ-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-money-bill-transfer"></i>   
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">โครงการที่กำลังดำเนินการ</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">  1 โครงการ <br />200,000 บาท</p>            </div>
        </div>            
          <!-- Card จำนวนเงิน-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-money-bill-transfer"></i>   
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">โครงการใหม่เร็วๆ นี้</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200"> 833,605.74 บาท</p>            </div>
        </div>          
    </div>
        </div>


      </div>
    </div>
  </section>

  <section class="bgcc">
                <center>
                    สนับสนุนโดย<br>
                <img style='max-width:200px;' src="@/assets/img/nia.png" alt="Services"></center>
            <br>
        </section>

</template>

<script>

export default {
 name: 'Home',

   data(){
       return{
           // url: process.env.VUE_APP_URL,
           // baseURLAPI: process.env.VUE_APP_URL_API,
           // title: process.env.VUE_APP_TITLE
       }
   }

}
</script>
