<template>
    <!-- สะสางดอกเบี้ย -->
    <section class="bgcc">
      <div class="container pt-20 mx-auto">
        <div class="flex flex-wrap items-center mt-3">
          <div class="order-first w-full p-4 lg:w-7/12 lg:order-first">
            <h1 class="mt-6 mb-4 text-4xl font-semibold leading-tight text-gray-800 capitalize">สะสางดอกเบี้ย  จากธนาคาร หรือ สหกรณ์</h1>
            <h3>ทางเลือกเพื่อความสะอาดใจ.... สู่การร่วมกันสร้างสรรค์สังคมที่ดีกว่ากับ...<br />กองทุนสะสางดอกเบี้ย มูลนิธิจูจีสร้างสรรค์สังคม</h3>
                <div class="items-center justify-center p-3 sm:p-6 md:w-full">
                    <div class="flex flex-wrap items-center justify-around w-full">
                      <div class="w-6/12 max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12"> 
                        <button @click="selectMoney(100)" class="w-40 h-24 px-4 py-1 text-2xl font-semibold border border-orange-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                          100 บาท
                        </button>
                      </div>
                      <div class="w-6/12 max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12">
                        <button @click="selectMoney(500)" class="w-40 h-24 px-4 py-1 text-2xl font-semibold border border-orange-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                          500 บาท
                        </button>
                      </div>
                      <div class="w-6/12 max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12">
                          <button @click="selectMoney(1000)" class="w-40 h-24 px-4 py-1 text-2xl font-semibold border border-orange-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                            1,000 บาท
                          </button>
                      </div>
                      <div class="w-6/12 max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12"> 
                        <button class="w-40 h-24 px-4 py-1 font-semibold border border-orange-200 rounded-sm text-l text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">ระบุจำนวน (บาท)
                        <br>
                        <input type="number" v-model="input_money" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder=""/>
                        </button>     
                      </div>
                    </div>
                    <div v-if="serverError.money" class="text-sm text-center text-red-500">
                      {{serverError.money[0]}}
                    </div>
                    <div v-if="!serverError.money" class="text-xl text-center text-bold">
                      <span v-if="money">{{showMoney(money)}} บาท</span>
                    </div>
                        <!-- <div class="content-center ">
                            <label class="mt-3 mb-3 font-semibold dark:text-gray-200 " for="ribaAmount">ระบุยอดดอกเบี้ย</label>
                            <input v-model="money" pattern="[0-9]+([\.,][0-9]+)?"
                                :class="{'border-red-500': serverError.money}" 
                                class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                type="number"/>
                            <div v-if="serverError.money" class="mt-1 text-red-500 ext-sm ">
                                {{serverError.money[0]}}
                            </div>
                        </div>   -->
                    <button @click="createOrder()" class="mt-3 btncc-riba" 
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled"
                    > 
                        สะสางดอกเบี้ย
                    </button>
                </div>
            </div>
            <div class="order-last w-full p-4 lg:w-5/12 lg:order-last">
                <div class="flex flex-col md:flex-row">
                    <div class="flex items-center justify-center p-3 sm:p-6 md:w-full">
                        <img
                            src="@/assets/img/bg_home.png"
                            class="w-full"
                            alt="Smart Inventory System"
                            width="50%"
                            height="50%"
                        />
                            
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
  
    <!-- สะสางดอกเบี้ย -->
  <section class="bgcc">
    <div class="container py-1 mx-auto">
      <div class="flex flex-wrap items-center ">
        <div class="order-last w-full p-4 lg:w-full lg:order-first">
          <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
        ข้อมูลการสะสางดอกเบี้ย
    </h2>
    
    <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-3">
          <!-- Card จำนวนวัน-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-calendar-days"></i>      
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">เริ่มรับสะสางดอกเบี้ยตั้งแต่ 30 มิถุนายน 2562 </p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">จำนวนวันสะสม 1,274 วัน</p>
            </div>
        </div>
          <!-- Card ครั้งที่โอน-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-money-bill-transfer"></i>   
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">จำนวนการสะสางสะสม</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">36,300 ครั้ง</p>
            </div>
        </div>      
          <!-- Card จำนวนเงิน-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-sack-dollar"></i>
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">จำนวนเงิน </p>
                <p class="text-lg font-semibold text-white dark:text-gray-200"> 2,636,555.74 บาท</p>
            </div>
        </div>            
    </div>
    <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
        ข้อมูลโครงการสาธารณประโยชน์
    </h2>
    <div class="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
          <!-- Card โครงการที่อนุมัติ-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              
              <i class="fa-solid fa-building"></i>
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">โครงการที่อนุมัติ</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">21 โครงการ <br />1,784,000 บาท</p>
            </div>
        </div>
          <!-- Card โครงการที่ดำเนินการเสร็จแล้ว-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-money-bill-transfer"></i>   
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">โครงการที่ดำเนินการเสร็จแล้ว</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">18 โครงการ <br />1,584,000 บาท</p>
            </div>
        </div>      
          <!-- Card โครงการที่กำลังดำเนินการ-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-money-bill-transfer"></i>   
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">โครงการที่กำลังดำเนินการ</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200">  1 โครงการ <br />200,000 บาท</p>            </div>
        </div>            
          <!-- Card จำนวนเงิน-->
          <div class="flex items-center p-4 bg-orange-500 rounded-lg shadow-xs dark:bg-orange-800">
            <div class="p-3 mr-4 text-orange-500 bg-orange-100 rounded-full dark:text-orange-100">
              <i class="fa-solid fa-money-bill-transfer"></i>   
            </div>
            <div>
                <p class="mb-2 text-sm font-medium text-white dark:text-gray-400">โครงการใหม่เร็วๆ นี้</p>
                <p class="text-lg font-semibold text-white dark:text-gray-200"> 833,605.74 บาท</p>            </div>
        </div>          
    </div>
        </div>


      </div>
    </div>
  </section>
  
    
  </template>
  
  
<script>
    import filter from '@/helpers/filter'
    import http from '@/services/AuthService'
    export default{
      mounted(){
        localStorage.setItem('lastVisit', 'Riba')
        window.scrollTo({top: 0, behavior: 'smooth'});
      },
      data(){
          return{
              money: '',
              input_money: '',
              disabled: false,
              serverError: {},    
          }
      },
      watch:{
        input_money: function(){
          this.money = this.input_money
        }
      },
      methods:{
        selectMoney(money){
          this.money = money
          this.input_money = ''
          this.serverError = {}
        },
        createOrder(){
            this.disabled = true
            this.$Progress.start()
            // http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
                // .then(() => {
                    // this.disabled = true
                    localStorage.removeItem('zakat_amount')
                    let data = new FormData()
                    data.append('money', this.money)
                    http.post('order/createRibaOrder', data)
                        .then(response => {
                            localStorage.setItem('riba_amount', this.money)
                            this.$Progress.finish()
                            this.serverError = ''
                            this.$store.dispatch("authMember/retreiveCart")
                            this.$router.push({name: 'RibaConfirmation', params: {'order_id': response.data.order_id}})
                        })  
                        .catch(error => {
                            this.disabled = false
                            this.$Progress.fail()
                            this.serverError = error.response.data.errors
                            if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                                this.$store.dispatch('authMember/unauthenticateResetting')
                            }  
                        })  
            // })
        },
        showMoney(money){
              return filter.numberFormat(money, '0,0.00')
          },
      }
    }
</script>