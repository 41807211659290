import http from '@/services/AuthService'
import httpFrontend from '@/services/FrontendService.js'
import router from '@/router'

const state = {
    token: localStorage.getItem('ccan') || null,
    myCart: localStorage.getItem('myCart'),
    modalBackdrop: false
}

const getters = {
    logedIn(state) { 
        return state.token != null
    },
    cart(state){
        return state.myCart
    },
    backdrop(state){
        return state.modalBackdrop
    }
}

const mutations = {
    signIn(state, token){
        state.token = token
    },
    socialSignIn(state, token){
        state.token = token
    },
    signOut(state){
        state.token = null
        localStorage.removeItem('ccan')
        localStorage.removeItem('ccups')
        localStorage.removeItem('ccur')
    },
    myCart(state, myCart){
        state.myCart = myCart
    },
    changeBackdrop(state){
        state.modalBackdrop = !state.modalBackdrop
    }
}

const actions = {
    // destroyUserInfo(){
    //     localStorage.removeItem('ccan')
    //     localStorage.removeItem('ccups')
    //     localStorage.removeItem('ccur')
    //     router.push({name: 'Login'})
    // },
    // refreshExpiredToken(){
    //     return new Promise((resolve, reject) => {
    //         httpFrontend.post(`refreshExpired`)
    //             .then(response => {
    //                 resolve(response)
    //             })
    //             .catch(error => {
    //                 reject(error)
    //             })
    //     })
    // },
    unauthenticateRefresh(context){
        context.commit('signOut')
    },
    unauthenticateResetting(context){
        context.commit('signOut')
        
        router.push({name: 'Login'})
    },
    signOut(context){
        return new Promise((resolve, reject) => {
            httpFrontend.post(`logout`)
                .then(response => {
                    context.commit('signOut')
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    socialSignIn(context, payload){
        localStorage.setItem('ccan', true) //authentication
        localStorage.setItem('ccups', [payload.permissions]) //user permission
        localStorage.setItem('ccur', [payload.roles])  //user role
        context.commit('socialSignIn', payload)    
    },
    signIn(context, credential){
        return new Promise((resolve, reject) => {
            http.post(`login`, credential)
                .then(response => {
                    // localStorage.setItem('ccan', JSON.stringify(response.data)) //authentication
                    localStorage.setItem('ccan', true) //authentication
                    localStorage.setItem('ccups', [response.data.permissions]) //user permission
                    localStorage.setItem('ccur', [response.data.roles])  //user role
                    context.commit('signIn', response.data)
                    resolve(response)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    retreiveCart(context){
        return new Promise((resolve, reject) => {
            http.get(`cart/myCart`)
                .then(response => {
                    context.commit('myCart', response.data.myCart)
                    localStorage.setItem('myCart', response.data.myCart)
                })
                .catch(error => {
                    reject(error)
                })
        })
    },
    changeBackdrop(context){
        context.commit('changeBackdrop')
    }
}

export default{
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}