<template>
  <section class="">
      <div class="container mx-auto lg:pt-24 ">
          <div class="flex flex-wrap">
              
              <div class="w-full p-4 lg:w-12/12 lg:ml-auto">
                  <h6 class="pt-24 font-medium text-indigo-900 lg:pt-10">ความรู้ / บทความ / การเงินในอิสลาม</h6>
                  <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize ">การเงินในอิสลาม ตอนที่ 1 </h2>
                  <img src="@/assets/img/m3.jpg" class="object-cover w-full h-40 pt-14 2xl:w-full lg:w-full lg:h-60 sm:pt-10" alt="" width="" height="" />

                  <p class="mb-4"><br>ขอเริ่มต้นด้วยเรื่อง "ดอกเบี้ย" ในทัศนะอิสลามนั้นเป็นบาปใหญ่ประเภทหนึ่ง และเป็นสิ่งที่ต้องห้ามในทุกศาสนาที่เป็นศาสนาแห่งฟากฟ้า (ศาสนาที่มีคัมภีร์จากพระผู้เป็นเจ้าซึ่งรวมศาสนายูดายของยิว ศาสนาคริสต์ และศาสนาอิสลาม) เนื่องจากมันแฝงไปด้วยความเสียหายที่ใหญ่หลวง ดอกเบี้ยเป็นต้นเหตุของการเป็นศัตรูกันระหว่างมนุษย์ และนำไปสู่การงอกเงยของทรัพย์สินผ่านการขูดรีดจากทรัพย์สินของคนจน เป็นการอธรรมต่อผู้ที่มีความจำเป็น ทำให้คนรวยกดขี่คนยากจน ปิดหนทางการบริจาคและการทำดีต่อผู้อื่น และทำลายความรู้สึกเมตตาสงสารต่อเพื่อนมนุษย์ 
                    <br><br>นักวิชาการมุสลิมได้ให้แนวทางในการใช้เงินดอกเบี้ยได้ เพื่อใช้ในสาธารณประโยชน์ เช่น การสร้างห้องน้ำสาธารณะ (มัสยิด วัด โรงเรียน)  สร้างถนน สร้างสะพาน  สร้างอาคารเรียน สร้างตึกผู้ป่วย  การจัดซื้ออุปกรณ์ทางการแพทย์ที่ขาดแคลน และวัสดุต่างๆ ที่จำเป็นในยามวิกฤต ฯลฯ เป็นต้น เพื่อให้เกิดการทำงานเป็นระบบ เชื่อถือได้  มีความโปร่งใส มีความปลอดภัย ถูกต้องตามหลักการศาสนาและกฎหมายแห่งรัฐไทย สามารถสร้างผลกระทบต่อสังคมในการยกระดับสาธารณประโยชน์ให้ได้มาตรฐาน  สร้างความเท่าเทียมกันของผู้คนในสังคมในการใช้สาธารณประโยชน์ สู่การสร้างคุณภาพชีวิตที่ดีขึ้นของพลเมือง สังคมที่น่าอยู่ และเกิดสันติสุขในพื้นที่
                    <br><br>บทบัญญัติอิสลามได้ห้ามการมีส่วนเกี่ยวข้องทุกกรณีในการดำเนินธุรกรรมที่มีดอกเบี้ย  แต่ปัจจุบันมุสลิมไม่สามารถหลีกเลี่ยงที่จะเกี่ยวข้องกับดอกเบี้ยในการทำธุรกรรมกับสถาบันการเงิน เช่น 
                    <br><br>พนักงานของรัฐ (ข้าราชการ พนักงานราชการ ลูกจ้าง ฯลฯ) ต้องเปิดบัญชีของธนาคารกรุงไทย และพนักงานเอกชนต้องเปิดบัญชีธนาคารที่นายจ้างกำหนดไว้ส่วนใหญ่เป็น ธนาคารพาณิชย์ Commercial bank ทำให้การเปิดบัญชีของพนักงานที่เป็นมุสลิมไม่สามารถใช้ธนาคารอิสลาม Islamic bank ที่ไม่มีระบบดอกเบี้ยได้ การเปิดบํยชีในรูปแบบธนาคารพาณิชย์ Commercial bank มีข้อบังคับจากธนาคารแห่งประเทศไทยให้ต้องปันผลดอกเบี้ยปีละ 2 ครั้งแก่ผู้ที่มีบัญชี 
                    <br><br>ประเทศไทยมีจำนวนประชากรประมาณ 66.5 ล้านคน (ข้อมูลจากกรมการปกครอง)  มีบัญชีเงินฝาก 102,740,281 บัญชี(ข้อมูลจากธนาคารแห่งประเทศไทย พฤษภาคม พ.ศ. 2563)   ในส่วนข้อมูลจำนวนประชากรไทยที่นับถือศาสนาอิสลาม หรือมุสลิมไทยนั้นมีข้อมูลหลายชุด สรุปได้ว่าจำนวนมุสลิมไทยมีประมาณ 5.4 ล้านคน (ข้อมูลปี 2560) การเปิดบัญชีแบบธนาคารพาณิชย์  คิดเป็นร้อยละ 3 ของบัญชีคนไทยทั้งหมด เป็น 3 ล้านบัญชี ซึ่ง 3 ล้านบัญชีถือว่าเป็นปัญหาใหญ่ของกลุ่มคนซึ่งทั้งหมดเป็นมุสลิมไทยที่มีจำนวนประชากรไทยมุสลิมที่สามารถมีช่องทาง
                    <br><br>ผู้ที่จะสะสางดอกเบี้ยไม่ทราบว่ามีช่องทางการสะสางดอกเบี้ยที่เข้าถึงได้สะดวกและง่าย รวมถึงความน่าเชื่อถือของผู้รับสะสางดอกเบี้ย  จำนวนดอกเบี้ยของแต่ละคนมีจำนวนน้อย บางคนมีเพียงไม่กี่สตางค์ ทำให้ไม่สามารถนำมาสร้างหรือพัฒนาสาธารณประโยชน์ที่เป็นรูปธรรมได้ ปัจจุบันมีการรับสะสางดอกเบี้ย ในรูปแบบทั้งรายบุคคลและองค์กรอยู่ ไม่มีการบริหารจัดการอย่างเป็นระบบ ในเรื่องความโปร่งใส เช่นการแสดงรายรับและรายจ่าย การทำกิจกรรมต่างๆ 
                    <br><br>ดอกเบี้ยไม่สามารถนำไปใช้ประโยชน์ส่วนตัวได้แต่สามารถนำไปใช้ประโยชน์ส่วนรวมหรือสาธารณประโยชน์ได้ดังนักวิชาการมุสลิมได้ให้คำชี้ขาดไว้  ดังนั้น ทีมงานมีไอเดียในการแก้ปัญหาของกลุ่มคนหลายล้านคน โดยจัดตั้งองค์กรเพื่อมาบริหารจัดการการสะสางดอกเบี้ย ในรูปแบบแพลตฟอร์ม
                    <br><br>แพลตฟอรม์ CuCi  เป็นแพลตฟอร์มที่พัฒนาขึ้นมา เพื่อการบริหารจัดการดอกเบี้ยเพื่อสาธารณประโยชน์ เป็นพื้นที่กลางระหว่างผู้สะสางดอกเบี้ยและผู้ขอทุนสนับสนุน โดยฝ่ายที่ 1 คือผู้ที่มีดอกเบี้ยสามารถเข้ามาสะสางในแพลตฟอร์ม และฝ่ายที่ 2 คือกลุ่มคน องค์กร หน่วยงาน และชุมชน เข้ามายื่นขอรับทุนสนับสนุนในการพัฒนาสาธารณประโยชน์ในพื้นที่	
                    <br><br>แพลตฟอร์ม CuCi ที่นำเครื่องมือดิจิทัลมาใช้ในการบริหารจัดการสะสางดอกเบี้ยเพื่อสาธารณประโยชน์ เป็นทางเลือกสำหรับมุสลิมที่มีดอกเบี้ยในการสะสางดอกเบี้ย เป็นพื้นที่รับมอบและรวบรวมการสะสางดอกเบี้ย เปิดรับโครงการสาธารณประโยชน์ (รับสมัคร คัดเลือก อนุมัติ ติดตาม ส่งมอบ ประเมิน สรุปผล) ให้แก่ผู้ขอรับทุนสนับสนุนโครงการ ที่เป็นระบบระเบียบ มีความโปร่งใส โดยใช้หลักการ Open Data  และผู้ใช้งานแพลตฟอร์มสามารถเลือกโครงการที่ต้องการสนับสนุน Democracy Vote</p>
                  <a href="#" class="inline-block px-6 py-2 text-white bg-indigo-600 border-2 border-indigo-600 rounded-md hover:text-indigo-600 hover:bg-indigo-100">เรียนรู้เพิ่มเติม</a>
              </div>
          </div>
      </div>
  </section>
</template>
