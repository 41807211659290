import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/tailwind.css'
import numerify from 'numerify'
import role from './mixins/Role'
import 'tw-elements';
// import '@ocrv/vue-tailwind-pagination/styles.css'
// import VueSocialauth from 'vue-social-auth'
//sweet alert 2
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

//vue-progressbar
import VueProgressBar from "@aacassandra/vue3-progressbar"; //vue-progressbar

import './assets/tailwind.css'
import axios from 'axios'
import UniversalSocialauth from 'universal-social-auth'

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)

const options = {
    color: "#234BEF",
    failedColor: "red",
    thickness: "5px",
    transition: {
        speed: "0.2s",
        opacity: "0.6s",
        termination: 300,
    },
    autoRevert: true,
    location: "top",
    inverse: false,
    //universal social login
    providers: {
        google: {
          clientId: '396805979459-3tovu9jgj7p1u2998bkufo2j7b795cl2.apps.googleusercontent.com',
          // redirectUri: 'http://localhost:8080/login'
          redirectUri: 'http://demo.cu-ci.org/login'
        },
      }

};

const Oauth = new UniversalSocialauth(axios, options)

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueSweetalert2)
app.use(VueProgressBar, options)
app.use(numerify)
app.mixin(role)
app.config.globalProperties.$Oauth = Oauth
app.config.globalProperties.$axios = axios
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')
