<template>
    <section class="bg-orange-200">
        <div class="container pt-20 pb-20 mx-auto lg:pt-40 sm:w-full md:w-3/4 lg:w-2/4">
            <div class="container ">
                <div class="mt-5 md:mt-0 md:col-span-2">
                    <div class="ml-2 mr-2 shadow sm:rounded-md sm:overflow-hidden">
                       
                        <!-- profile -->
                        <div class="px-4 py-5 space-y-6 bg-white sm:p-6">
                            <h3 class="flexcc-top">
                                เปลี่ยนรหัสผ่าน
                                <router-link to="/account">
                                    <button type="button" class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-yellow-600 border border-transparent rounded-md shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                        <i class="fa-solid fa-circle-left"></i> &nbsp; กลับ
                                    </button>
                                </router-link>
                            </h3>
                            <form @submit.prevent="updateAccount()">
                                <!-- <div class="grid grid-cols-6 gap-6 mt-2">
                                    <div class="col-span-12 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">รหัสผ่านเดิม</label>
                                        <input type="password" 
                                            v-model="current_password"
                                            :class="{'border-red-500': serverError.current_password}"
                                            class="mt-1 cc_input_text"/>
                                        <div v-if="serverError.current_password" class="mt-1 text-sm text-red-500">
                                            {{serverError.current_password[0]}}
                                        </div>
                                    </div>
                                </div> -->
                                <div class="grid grid-cols-6 gap-6 mt-2">
                                    <div class="col-span-12 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">รหัสผ่านใหม่</label>
                                        <input type="password"
                                            v-model="new_password"
                                            :class="{'border-red-500': serverError.new_password}"
                                            class="mt-1 cc_input_text"/>
                                        <div v-if="serverError.new_password" class="mt-1 text-sm text-red-500">
                                            {{serverError.new_password[0]}}
                                        </div>
                                    </div>
                                </div>
                                <div class="grid grid-cols-6 gap-6 mt-2">
                                    <div class="col-span-12 sm:col-span-12 lg:col-span-12">
                                        <label class="block text-sm font-medium text-gray-700">ยืนยันรหัสผ่านใหม่</label>
                                        <input type="password"
                                            v-model="new_confirm_password"
                                            :class="{'border-red-500': serverError.new_confirm_password}"
                                            class="mt-1 cc_input_text"/>
                                        <div v-if="serverError.new_confirm_password" class="mt-1 text-sm text-red-500">
                                            {{serverError.new_confirm_password[0]}}
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <button type="submit"
                                        class="inline-flex justify-center px-3 py-2 mt-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500">
                                        บันทึก
                                    </button>
                                </div>
                            </form>
                        </div>
                        <!-- \.profile -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import http from '@/services/AuthService'
    export default {
        data(){
            return{
                current_password: '',
                new_password: '',
                new_confirm_password: '',

                //server error
                disabled: false,
                serverError: {},
            }
        },
        methods:{
            updateAccount(){
                this.disabled = true;
                this.$Progress.start()
                let data = new FormData()
                // data.append('current_password', this.current_password)
                data.append('new_password', this.new_password)
                data.append('new_confirm_password', this.new_confirm_password)
                http.post(`member/updateAccount`, data)
                    .then(response => {
                        console.log(response.data.data)
                        this.disabled = false
                        this.$Progress.finish()
                        this.serverError = {}
                        this.current_password = ''
                        this.new_password = ''
                        this.new_confirm_password = ''
                        //เรียก popup ของ sweet alert 2
                        const Toast = this.$swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', this.$swal.stopTimer)
                            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                        }
                        })
                        
                        Toast.fire({
                            icon: 'success',
                            title: 'แก้ไขรหัสผ่านเรียบร้อย'
                        })
                    })
                    .catch(error => {
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()
                        this.disabled = false
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }    
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>