<template>
    
    <a class="b_title">
        <div class="flex items-center">
            <span> จัดการบทบาท</span>
        </div>
    </a>

    <!-- ฟอร์มสำหรับค้าหา -->
    <div class="flex items-center justify-between my-8">
        <div class="flex flex-1 justify-left lg:mr-32">
            <div class="relative w-full max-w-xl mr-6 focus-within:text-gray-500">
                <div class="absolute inset-y-0 flex items-center pl-2">
                    <svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                    </svg>
                </div>
                <form @submit.prevent="submitSearchForm">
                    <input
                        v-model="q"
                        class="w-full py-2 pl-8 pr-2 text-sm text-gray-700 placeholder-gray-600 bg-gray-200 border-0 rounded-md"
                        type="text"
                        placeholder="ป้อนหมวดหมู่โครงการ"
                        aria-label="Search"
                    />
                </form>
            </div>
            <div>
                <button :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" @click="retreiveRole()" class="flex items-center justify-between px-4 py-1.5 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-yellow-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-yellow-700 focus:outline-none focus:shadow-outline-purple">
                    <svg class="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                    </svg>
                    <span>ล้าง</span>
                </button>
            </div>
        </div>
    
        <button @click="openFormModal('', false)" class="flex items-center justify-between px-4 py-2 mx-1 text-sm font-medium leading-5 text-white transition-colors duration-150 bg-green-500 border border-transparent rounded-lg active:bg-purple-600 hover:bg-green-700 focus:outline-none focus:shadow-outline-purple">
            <svg class="w-4 h-4 mr-2 -ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
            <span>เพิ่ม</span>
        </button>
        
    </div>
    <!-- \.ฟอร์มสำหรับค้าหา -->

    <!-- ตารางแสดงข้อมูล -->
    <div class="w-full overflow-hidden rounded-lg shadow-xs">
        <div class="w-full overflow-x-auto">
            <table class="w-full mb-3 whitespace-no-wrap">
                <thead>
                    <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                        <th class="px-4 py-3">Role (ทั้งหมด : {{pagination.total}})</th>
                        <th class="px-4 py-3">ชื่อสิทธิ</th>
                        <th class="px-4 py-3">เพิ่มเมื่อ</th>
                        <th class="px-4 py-3">แก้ไขเมื่อ</th>
                        <th class="px-4 py-3">แก้ไข</th>
                        <th class="px-4 py-3">จัดการสิทธิ์</th>
                    </tr>
                </thead>
                <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                    <tr v-for="role in roles" :key="role.id" class="text-gray-700 dark:text-gray-400 hover:bg-blue-100">
                        <td class="px-4 py-3 text-sm">
                            {{role.name}}
                        </td>
                        <td>
                            {{role.name_thai}}
                        </td>
                        <td>
                            {{formatDate(role.created_at)}}
                        </td>
                        <td>
                            {{formatDate(role.updated_at)}}
                        </td>
                        <td class="px-4 py-3">
                            <div class="flex items-center space-x-4 text-sm">
                                <button @click="openFormModal(role, true)" class="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-purple-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray" aria-label="Edit">
                                    <!-- <svg class="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                                    </svg> -->
                                    <font-awesome-icon icon="fa-edit" />    
                                </button>
                            </div>   
                        </td>
                        <td class="px-4 py-3">
                            <div class="flex items-center space-x-4 text-sm">
                                <router-link :to="{name: 'RoleHasPermission', params:{id: role.id}}" class="flex items-center justify-between ">
                                    <font-awesome-icon icon="fa-gear" />    
                                </router-link>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- pagination -->
            <VueTailwindPagination class="mb-3" v-if="roles.length"
                :current="pagination.current_page"
                :total="pagination.total"
                :per-page="pagination.per_page"
                @page-changed="onPageClick($event)"
            />
            <!-- \.pagination -->

        </div>
    </div>
    <!-- \.ตารางแสดงข้อมูล -->

    <!-- ฟอร์มเพิ่ม/แก้ไขข้อมูล -->
    <div v-if="showFormModal" id="showFormModal" class="fixed top-0 left-0 flex items-center justify-center w-full h-full modal">
        <div class="absolute w-full h-full bg-gray-900 opacity-70 modal-overlay"></div>
        <div class="z-50 w-11/12 p-5 mx-auto overflow-y-auto bg-white rounded shadow-lg h-4/5 modal-container md:max-w-md">
            <!-- Header -->
            <div class="flex items-center justify-center w-full h-auto">
                <div class="flex items-start justify-start w-full h-auto py-2 text-xl font-bold">
                    <span v-if="!editMode">เพิ่ม</span>
                    <span v-else>แก้ไข</span>
                </div>
                <div @click="closeFormModal" class="flex justify-center w-1/12 h-auto cursor-pointer">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </div>
            <!--Header End-->
            </div>
            <!-- Modal Content-->
            <div class="w-full h-auto mb-4">

                <form ref="roleForm" @submit.prevent="!editMode ? createRole() : updateRole()" enctype="multipart/form-data">
                    
                    <label class="block my-3 text-gray-700 text-md" for="name">Role name</label>
                    <input v-model="data.form.name" :class="{'border-red-500': serverError.name}" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow" type="text" placeholder="Role name">
                    <div v-if="serverError.name" class="mt-2 text-sm text-red-500">
                        {{serverError.name[0]}}
                    </div>
                    
                    <label class="block my-3 text-gray-700 text-md" for="name">ชื่อสิทธ์</label>
                    <input v-model="data.form.name_thai" :class="{'border-red-500': serverError.name_thai}" class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow" type="text" placeholder="ชื่อสิทธ์">
                    <div v-if="serverError.name_thai" class="mt-2 text-sm text-red-500">
                        {{serverError.name_thai[0]}}
                    </div>

                    <label v-if="editMode" class="block my-3 text-gray-700 text-md" for="description">code</label>
                    <textarea v-model="data.form.code" v-if="editMode" disabled
                        class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow"
                        rows="5"
                        placeholder="รายละเอียดหมวดหมู่"
                    ></textarea>
                    <div v-if="serverError.code" class="mt-2 text-sm text-red-500">
                        {{serverError.code[0]}}
                    </div>

                    <div class="grid grid-cols-3 gap-4">
                        
                        <div class="col-span-2" :class="{'col-span-12': editMode}">
                            <button :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" type="submit" class="w-full px-4 py-2 mt-4 font-medium leading-5 text-white transition-colors duration-150 bg-blue-600 border border-transparent rounded-lg text-md active:bg-purple-600 hover:bg-blue-700 focus:outline-none focus:shadow-outline-purple">
                                <span v-if="!editMode">บันทึก</span> 
                                <span v-else>แก้ไข</span>
                            </button>
                        </div>

                        <div>
                            <button v-if="!editMode" type="button" @click="onResetForm" class="w-full px-4 py-2 mt-4 font-medium leading-5 text-white transition-colors duration-150 bg-gray-500 border border-transparent rounded-lg text-md active:bg-purple-600 hover:bg-gray-700 focus:outline-none focus:shadow-outline-purple">
                                ล้าง
                            </button>
                        </div>
                        
                    </div>

                </form>
                
            </div>
        </div>
    </div>
    <!-- ฟอร์มเพิ่ม/แก้ไขข้อมูล -->
    
</template>

<script>
    import VueTailwindPagination from '@ocrv/vue-tailwind-pagination'
    import http from '@/services/BackendService.js'
    import moment from 'moment'
    export default {
        
        components:{
           VueTailwindPagination 
        },
        
        mounted(){
            this.retreiveRole()
        },

        data() {
            return {
                roles: [],    
                //pagination
                pagination: {
                    current_page: 1,
                },
                //search
                q: '',
                searchDisabled: false,
                disabled: false,
                //form
                showFormModal: false,
                editMode: false,
                serverError: '',
                data:{
                    form: {
                        id: '',
                        name: '',
                        name_thai: '',
                        code: '',
                    }
                },
            }
        },

        watch:{
            q: function(){
                if(this.q != ""){
                    this.submitSearchForm()
                }else{
                    this.retreiveRole()
                }
            }
        },

        methods: {
            submitSearchForm(){
                http.get(`role/search?q=${this.q}&page=${this.pagination.current_page}`)
                    .then(response => {
                        this.roles = response.data.roles.data
                        this.pagination = response.data.roles
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()   
                    })
            },
            updateRole(){
                let data = new FormData()
                data.append('name', this.data.form.name)
                data.append('name_thai', this.data.form.name_thai)
                data.append('_method', 'PUT')
                this.disabled = true
                this.$Progress.start()
                http.post(`role/role/${this.data.form.id}`, data)
                    .then(() => {
                        this.$store.dispatch('authMember/refreshExpiredToken')
                        //sweet alert popup
                        const Toast = this.$swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'แก้ไขข้อมูลสำเร็จ'
                        })
                        this.retreiveRole()
                        this.$Progress.finish()
                        this.disabled = false
                        this.serverError = ''
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()
                        this.disabled = false
                    })    
            },
            createRole(){
                let data = new FormData()
                data.append('name', this.data.form.name)
                data.append('name_thai', this.data.form.name_thai)
                this.disabled = true
                this.$Progress.start()
                http.post(`role/role`, data)
                    .then(() => {
                        // this.$store.dispatch('authMember/refreshExpiredToken')
                        //sweet alert popup
                        const Toast = this.$swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', this.$swal.stopTimer)
                                toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: 'เพิ่มข้อมูลสำเร็จ'
                        })
                        this.showFormModal = false
                        this.onResetForm()
                        this.$Progress.finish()
                        this.retreiveRole()
                        this.disabled = false
                        this.serverError = ''
                    })
                    .catch(error => {
                       if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.serverError = error.response.data.errors
                        this.$Progress.fail()
                        this.disabled = false
                    })
            },
            //สร้างฟังก์ชันสำหรับจัดรูปแบบวันที่
            formatDate(value){
                if(value){
                    return moment(String(value)).format('DD/MM/YYYY HH:mm:ss')
                }
            },
            onResetForm(){
                // this.$refs.roleForm.reset()
                var self = this; //you need this because *this* will refer to Object.keys below`

                //Iterate through each object field, key is name of the object field`
                Object.keys(this.data.form).forEach(function(key) {
                    self.data.form[key] = '';
                });
            },
            closeFormModal(){
                this.showFormModal = false
                this.editMode = false
            },
            openFormModal(role, editMode){
                this.showFormModal = true
                this.editMode = editMode
                if(editMode){
                    this.data.form.id = role.id
                    this.data.form.name = role.name
                    this.data.form.name_thai = role.name_thai
                    this.data.form.code = role.code
                }else{
                    this.onResetForm()
                }
            },
            retreiveRole(){
                this.$Progress.start()
                this.q = ''
                http.get(`role/role?page=${this.pagination.current_page}`)
                    .then(response => {
                        this.roles = response.data.roles.data
                        this.pagination = response.data.roles
                        // this.currentPage = response.data.roles.current_page
                        // this.perPage = response.data.roles.per_page
                        // this.total = response.data.roles.total
                        this.$Progress.finish()
                    })   
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()
                    }) 
            },
            onPageClick(event){
                console.log(event)
                this.$Progress.start()
                http.get(`role/role?page=${event}`)
                    .then(response => {
                        this.roles = response.data.roles.data
                        this.pagination = response.data.roles
                        // this.currentPage = response.data.roles.current_page
                        // this.perPage = response.data.roles.per_page
                        // this.total = response.data.roles.total
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                           this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                        this.$Progress.fail()    
                    })
            }
        },
        
    }
</script>