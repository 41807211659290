<template>
    <section class="bgcc">
        <div class="container pt-40 pb-20 mx-auto sm:w-full md:w-3/4 lg:w-2/4">
            <div class="w-full px-4 mb-4 lg:mx-auto">
                <h1 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">ยืนยันการการโอนเงินซากาต</h1>
                <h2 class="mb-2 text-xl font-semibold text-center text-gray-800 dark:text-gray-200">
                    หลังจากที่โอนเงินแล้ว กรุณายืนยันการโอนเงินจากฟอร์มด้านล่าง
                </h2>
            </div>
            <form @submit.prevent="checkout()">
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                วันที่โอน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-4/12 mt-1 mr-1 md:mr-1 lg:mr-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.year}"
                                    v-model="year">
                                    <option style="display: none">--ปี--</option>
                                    <option v-for="(year, index) in yearList" :value="year" :key="index">{{year}}</option>
                                </select>
                                <div v-if="serverError.year" class="mt-2 text-sm text-red-500">
                                    {{serverError.year[0]}}
                                </div>
                            </div>
                            <div class="w-4/12 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.month}"
                                    v-model="month"
                                    @change="changeMonth($event.target.value)">
                                    <option value="" style="display: none">--เดือน--</option>
                                    <option v-for="(month, index) in 12" :value="formatMonth(month)" :key="index">{{formatMonth(month)}}</option>
                                </select>
                                <div v-if="serverError.month" class="mt-2 text-sm text-red-500">
                                    {{serverError.month[0]}}
                                </div>
                            </div>
                            <div class="w-4/12 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.day}"
                                    v-model="day">
                                    <option value="" style="display: none">--วันที่--</option>
                                    <option v-for="(day, index) in dayList" :value="day" :key="index">
                                        {{formatDay(day)}}
                                    </option>
                                </select>
                                <div v-if="serverError.day" class="mt-2 text-sm text-red-500">
                                    {{serverError.day[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                เวลาโอน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-2/4 mt-1 mr-1 md:mr-1 lg:mr-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.hour}"
                                    v-model="hour">
                                    <option value="" style="display: none">--ชั่วโมง--</option>
                                    <option v-for="(h, index) in 24" :value="hourFormat(index)" :key="index">{{hourFormat(index)}}</option>
                                </select>
                                <div v-if="serverError.hour" class="mt-2 text-sm text-red-500">
                                    {{serverError.hour[0]}}
                                </div>
                            </div>
                            <div class="w-2/4 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                                    :class="{'border-red-500': serverError.minute}"
                                    v-model="minute">
                                    <option value="" style="display: none">--นาที--</option>
                                    <option v-for="(m, index) in 60" :value="minuteFormat(index)" :key="index">{{minuteFormat(index)}}</option>
                                </select>
                                <div v-if="serverError.minute" class="mt-2 text-sm text-red-500">
                                    {{serverError.minute[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-2/4 mt-2 ml-1 md:ml-1 lg:ml-1">
                                หลักฐานการโอนเงิน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-2/4 mt-1 ml-1 md:ml-1 lg:ml-1">
                                <input type="file" accept="image/png, image/gif, image/jpeg"
                                    class="cc_input_text"
                                    :class="{'border-red-500': serverError.slip}"
                                    @change="onFileChange">
                                <div v-if="serverError.slip" class="mt-2 text-sm text-red-500">
                                    {{serverError.slip[0]}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-2/4 mt-2 mr-1 md:mr-1 lg:mr-1">
                                จำนวนเงินที่โอน
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-2/4 mt-1 mr-1 md:mr-1 lg:mr-1">
                                <!-- <input type="number" :min="money" v-model="money" 
                                    :class="{'border-red-500': serverError.money}"
                                    class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none">
                                <div v-if="serverError.money" class="mt-2 text-sm text-red-500">
                                    {{serverError.money[0]}}
                                </div> -->
                                {{showMoney(money)}} บาท
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="mt-2 mr-1 md:mr-1 lg:mr-1 sm:w-full md:w-2/4">
                                หมายเหตุ
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex ml-10 mr-10 rounded-lg">
                    <div  class="flex-1 max-w-4xl">
                        <div class="flex md:flex-row">
                            <div class="w-full mt-1 mr-1 md:mr-1 lg:mr-1">
                                <textarea rows="5"
                                class="w-full px-3 py-2 leading-tight text-gray-700 bg-white bg-auto border rounded"
                                :class="{'border-res-500': serverError.description}"
                                v-model="description"
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex mt-2 ml-10 mr-10 rounded-lg ">
                    <button
                        class="w-full mt-1 btncc-riba"
                        :class="{'opacity-50 cursor-not-allowed': disabled}"
                        :disabled="disabled">
                    ยืนยันการโอนเงิน
                    </button>
                </div>
            </form>
            
        </div> 
    </section>
</template>

<script>
    import http from '@/services/FrontendService'
    import filter from '@/helpers/filter'
    import moment from 'moment'
    
    export default {
        mounted(){
            this.retreiveCart()
        },
        data(){
            return{
                serverError: {},
                disabled: false,
                
                order_id: '',
                yearList: [],
                dayList: [],
                money: '',
                year: '',
                month: '',
                day: '',
                hour: '',
                minute: '',
                slip: '',
                description: '',
                
            }
        },
        created(){
            window.location.hash = 'top'
            let beforThisyear = moment().subtract(1, 'year').format("YYYY")
            let yearList = [parseInt(beforThisyear)]
            this.yearList.push(parseInt(beforThisyear))
            let i = 1
            while(i < 2){
                yearList.push(parseInt(yearList[i-1])+1)
                this.yearList.push(parseInt(yearList[i-1])+1)
                i++
            }
            this.year = moment().format("YYYY")
            this.month = moment().format("MM")
            this.dayList = moment().daysInMonth()
            this.day = moment().format("D")
        },
        methods:{
            checkout(){
                this.disabled = true
                this.$Progress.start()
                let data = new FormData()
                data.append("money", this.money)
                data.append("year", this.year)
                data.append("month", this.month)
                data.append("day", this.day)
                data.append("hour", this.hour)
                data.append('minute', this.minute)
                data.append('slip', this.slip)
                data.append('order_id', this.order_id)
                // data.append('project_id', this.project_id)
                // data.append('_method', 'patch')
                http.post('zakat/createBankingTransaction', data)
                    .then(response => {
                        if(response.data.isExist == true){
                            this.$swal.fire({
                                icon: 'warning',
                                title: 'ขออภัย',
                                text: 'กรุณาตรวจสอบสลิบโอนเงิน และกรอกวัน เวลาโอนให้ถูกต้อง',
                                confirmButtonText: 'OK',
                            })    
                        }else{
                            // this.$store.dispatch("authMember/retreiveCart")
                            this.$swal.fire({
                                icon: 'success',
                                title: 'สำเร็จ',
                                text: 'ขอบคุณที่จ่ายซากาตกับจูจี',
                                confirmButtonText: 'OK',
                            }).then(() => {
                                this.$router.push({name: 'Zakat'})
                                localStorage.removeItem('donateAmount')
                            })
                        }
                        this.serverError = ''   
                        this.disabled = false
                        this.$Progress.finish()
                    })
                    .catch(error => {
                        this.serverError = error.response.data.errors
                        this.disabled = false
                        this.$Progress.fail()
                    })
            },
            retreiveCart(){
                http.get(`cart/cardDetail/${this.$route.params.order_id}`)
                    .then(response => {
                        if(response.data.data.length == 0)
                        {
                            this.$router.push({name: 'Donate'})
                        }
                        this.$store.dispatch("authMember/retreiveCart")
                        this.cartItem = response.data.data
                        let totalMoney = 0
                        response.data.data.forEach(element => {
                            totalMoney = parseFloat(totalMoney + element.amount)
                        });
                        this.money = totalMoney
                        this.order_id = this.$route.params.order_id
                    })
                    .catch(error => {
                        if(error.response.status == 401){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }  
                    })
            },
            onFileChange(e){
                const slip = e.target.files[0]
                this.slip = e.target.files[0]
                this.imgUrl = URL.createObjectURL(slip)
            },
            minuteFormat(m){
                return moment(m, "mm").format("mm")
            },
            hourFormat(h){
                return moment(h, "HH").format("HH")
            },
            changeMonth(month){
               this.dayList = moment(moment().month(month-1).year(this.year).format('YYYY-MM')).daysInMonth()
            },
            formatMonth(month){
                return moment(month, "m").format("mm")
            },
            formatDay(d){
                return filter.dayFormat(d)
            },
            copyToClipboard(){
                let accountNumber = document.querySelector('#accountNumber')
                accountNumber.setAttribute('type', 'text')
                accountNumber.select()
                try {
                    document.execCommand('copy');
                    this.is_copied = false
                } catch (err) {
                    alert('Oops, unable to copy');
                }
                /* unselect the range */
                accountNumber.setAttribute('type', 'hidden')
                window.getSelection().removeAllRanges() 
            },
            showMoney(money){
                return filter.numberFormat(money, '0,0.00')
            },
        } 
    }
</script>

<style lang="scss" scoped>

</style>