<template>
    <!-- slider -->
    <section>
      <div class="hidden w-full pt-24 mx-auto md:block">
      <div id="carouselExampleCaptions" class="relative carousel slide" data-bs-ride="carousel">
          <div class="absolute bottom-0 left-0 right-0 flex justify-center p-0 mb-4 carousel-indicators">
          <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
          ></button>
          <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
          ></button>
          <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
          ></button>
          </div>
          <div class="w-full overflow-hidden carousel-inner">
          <div class="float-left w-full carousel-item">
              <img
              src="@/assets/img/derma_bg.png"
              class="block w-full"
              alt="..."
              />
              <div class="absolute hidden text-center carousel-caption md:block">
              <h5 class="text-xl">บริจาค</h5>
              <p>มอบโอกาสสู่การร่วมกันพํฒนาและสร้างสรรค์สังคมที่ดีกว่าไปร่วมกัน</p>
              </div>
          </div>
          <div class="float-left w-full carousel-item active">
              <img
              src="@/assets/img/bg.png"
              class="block w-full"
              alt="..."
              />
              <div class="absolute hidden text-center carousel-caption md:block">
              <h5 class="text-xl">CUCI</h5>
              <p>สร้างสรรค์สังคมที่ดีกว่าร่วมกัน</p>
              </div>
          </div>
          
      
          </div>
          <button
          class="absolute top-0 bottom-0 left-0 flex items-center justify-center p-0 text-center border-0 carousel-control-prev hover:outline-none hover:no-underline focus:outline-none focus:no-underline"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
          >
          <span class="inline-block bg-no-repeat carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
          </button>
          <button
          class="absolute top-0 bottom-0 right-0 flex items-center justify-center p-0 text-center border-0 carousel-control-next hover:outline-none hover:no-underline focus:outline-none focus:no-underline"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
          >
          <span class="inline-block bg-no-repeat carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
          </button>
      </div>
      </div>
      <div class="w-full pt-20 mx-auto md:hidden lg:hidden xl:hidden">
      <div class="container py-1 mx-auto">
          <div class="flex flex-wrap items-center ">
            <div class="order-last w-full p-4 lg:w-full lg:order-first">
                <h2 class="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
                <img src="@/assets/img/bg.png" alt="">
                </h2>
            </div>
          </div>
      </div>
      </div>
    </section>
    <!-- \.slider -->

    <!-- <section class="bgcc">
      <div class="container w-full pt-10 pb-2 mx-auto text-center ">
        
        <div class="w-full px-4 py-3 lg:mx-auto">
          

          <h2
            class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize "
          >
            โครงการทั้งหมด
          </h2>
          <p></p>
          <p>
            <router-link to="/Derma-funding">กำลังระดมทุน |</router-link>
            <a href="">ดำเนินการเสร็จแล้ว</a> |
            <a href="">โครงการที่กำลังดำเนินการ</a>
            

          </p>
        </div>
      </div>
    </section> -->

    <section class="bgcc-riba">
      <div class="container pb-20 mx-auto text-center">
       <div class="px-4 mb-4 lg:mx-36">
          <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">
            <br>โครงการที่กำลังระดมทุน
          </h2>
          <br>
          <div class="flex flex-wrap justify-around ">
            <div class="flex items-center lg:flex-row sm:flex-col">
              <i class="ml-4 text-4xl text-emerald-700 fas fa-border-all"></i>
              <p class="ml-2 text-sm text-left ">หมวดหมู่ทั้งหมด</p>
            </div>
            <div class="flex items-center lg:flex-row sm:flex-col">
              <i class="mb-4 ml-4 text-4xl text-emerald-700 fa-school fas"></i>
              <p class="ml-2 text-sm text-left ">การศึกษา</p>
            </div>
            <div class="flex items-center lg:flex-row sm:flex-col">
              <i class="mb-4 ml-4 text-4xl text-emerald-700 fa-retweet fas"></i>
              <p class="ml-2 text-sm text-left ">ศาสนาและสังคม</p>
            </div>
            <div class="flex items-center lg:flex-row sm:flex-col">
              <i class="mb-4 ml-4 text-4xl text-emerald-700 fa-water fas"></i>
              <p class="ml-2 text-sm text-left ">ภัยพิบัติ</p>
            </div>
            <div class="flex items-center lg:flex-row sm:flex-col">
              <i class="mb-4 ml-4 text-4xl text-emerald-700 fa-heartbeat fas"></i>
              <p class="ml-2 text-sm text-left ">สุขภาพและการแพทย์</p>
            </div>
            <div class="flex items-center lg:flex-row sm:flex-col">
              <i class="mb-4 text-4xl text-emerald-700 fa-balance-scale fas"></i>
              <p class="ml-2 text-sm text-left ">กระบวนการยุติธรรม</p>
            </div>

          </div>
       </div>
       <br>
        <div class="flex items-center justify-around mb-4">
            <!-- <p>{{showModal}}</p> -->
            <div v-for="(item, index) in projects" :key="index" class="w-full p-4 lg:w-4/12 sm:w-6/12">
              <router-link :to="{name: 'ProjectDetail', params:{id: item.id}}">
                <a aria-current="page" href="/projects" class="block text-left router-link-active router-link-exact-active">
                  <img :src="item.image" alt="" class="object-fill rounded-t-lg" style="width: 490px; height: 350px; object-fit: cover;">
                </a>
              </router-link>
              <div class="p-6 bg-white shadow-lg">
                <router-link :to="{name: 'ProjectDetail', params:{id: item.id}}">
                  <h5 class="mb-2 text-xl font-medium text-left text-blue-900">
                    {{item.name.substring(0,100)+"..."}}
                  </h5>
                </router-link>
              <p class="mb-4 text-base text-left text-gray-700">
                {{item.description.substring(0,60)+"..."}}
              </p>
              <div class="flex justify-start">
                <div class="text-sm">เป้าหมาย</div>
              </div>
              <div class="flex justify-between mt-2">
                <div>{{showMoney(item.budget)}}</div>
                <div>{{showMoney(item.persent)}}%</div>
              </div>
              <div class="w-full bg-gray-200 rounded-full">
                <div class="bg-yellow-400 text-xs h-2 font-medium text-blue-100 text-center p-0.5 leading-none rounded-l-full" :style="`width: ${item.persent}%`"></div>
              </div> 
              
              <div class="flex justify-between mt-2">
                <div class="text-sm">เหลือ {{item.dayRemaining}} วัน</div>
                <div class="text-sm">ผู้ร่วมบริจาค {{item.totalDonator}} คน</div>
              </div>        
              <br>
              <div class="grid grid-cols-4 gap-4">
                <div class="col-span-2" v-if="myCart == 0 || !logedIn">
                  <router-link :to="{name: 'ProjectDetail', params:{id: item.id}}">
                    <button type="button" class="w-full inline-block px-6 py-2.5 bg-orange-600 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                      ร่วมบริจาค
                    </button>
                  </router-link>
                </div>
                <div class="col-span-2">
                  <!-- <button v-if="logedIn" @click="selectProject(item.id)" type="button" data-bs-toggle="modal" data-bs-target="#selectMoneyModal" class="w-full inline-block px-6 py-2.5 bg-orange-600 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                    <i class="text-l text-white-700 fas fa-shopping-cart"></i>
                  </button> -->
                  <button v-if="logedIn" @click="selectProject(item.id)" type="button"  data-bs-target="#selectMoneyModal" class="w-full inline-block px-2 py-2.5 bg-orange-600 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                    <i class="fas fa-shopping-basket"></i> หยิบใส่ตะกร้า
                  </button>
                  <button v-else type="button" data-bs-toggle="modal" data-bs-target="#loginAlertModal" class="w-full inline-block px-6 py-2.5 bg-orange-600 text-white font-medium text-l leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                    <i class="text-l text-white-700 fas fa-shopping-cart"></i>
                  </button>
                </div>
              </div>
            </div>
            </div>
            <!-- p002 -->
        </div>
      </div>
    </section>

    <!-- ฟอร์มระบุจำนวนเงิน -->
    <div class="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none modal fade" :class="[showModal ? ['show','show-modal'] : 'hide-modal'] "  id="selectMoneyModal" tabindex="-1" aria-labelledby="exampleModalCenteredScrollable" aria-modal="true" role="dialog">
      <div class="relative w-auto pointer-events-none modal-dialog-scrollable modal-lg modal-dialog">
        <div class="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
          <div class="flex items-center justify-between flex-shrink-0 p-4 border-b border-gray-200 modal-header rounded-t-md">
            <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalCenteredScrollableLabel">
              เลือกจำนวนเงิน (บาท)
            </h5>
            <button type="button"
              class="box-content w-1 h-1 p-1 text-black border-none rounded-none opacity-50 btn-close focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              @click="closeModal" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="flex flex-wrap items-center justify-around w-full">
              <div class="max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12"> 
                <button @click="selectMoney(100)" class="w-40 h-24 px-4 py-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                  100 บาท
                </button>
              </div>
              <div class="max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12">
                <button @click="selectMoney(500)" class="w-40 h-24 px-4 py-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                  500 บาท
                </button>
              </div>
              <div class="max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12">
                  <button @click="selectMoney(1000)" class="w-40 h-24 px-4 py-1 text-2xl font-semibold border border-purple-200 rounded-sm text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">
                    1,000 บาท
                  </button>
              </div>
              <div class="max-w-sm p-2 mt-1 rounded-lg lg:w-3/12 sm:w-6/12"> 
                <button class="w-40 h-24 px-4 py-1 font-semibold border border-purple-200 rounded-sm text-l text-black-600 hover:text-white hover:bg-green-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">ระบุจำนวน (บาท)
                <br>
                <input type="number" v-model="input_money" class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder=""/>
                </button>     
              </div>
            </div>
            <div v-if="serverError.money"  class="flex flex-wrap items-center justify-around w-full text-sm text-red-500">
              {{serverError.money[0]}}
            </div>
          </div>
          <div class="flex flex-wrap items-center justify-end flex-shrink-0 p-4 border-t border-gray-200 modal-footer rounded-b-md">
            <div class="w-full">
              <button @click="insertTocart()" 
                :disabled="disabled"
                :class="{'opacity-50 cursor-not-allowed': disabled}"
                class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-orange-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-orange-500">
                <i class="fas fa-shopping-basket"></i> หยิบใส่ตะกร้า
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- \.ฟอร์มระบุจำนวนเงิน -->

    <!-- ฟอร์มแจ้งเตือนให้เข้าสู่ระบบ -->
    <div class="fixed top-0 left-0 hidden w-full h-full overflow-x-hidden overflow-y-auto outline-none modal fade" id="loginAlertModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="relative w-auto pointer-events-none modal-dialog modal-dialog-scrollable modal-lg">
        <div class="relative flex flex-col w-full text-current bg-white border-none rounded-md shadow-lg outline-none pointer-events-auto modal-content bg-clip-padding">
          <div
            class="flex items-center justify-between flex-shrink-0 p-4 border-b border-gray-200 modal-header rounded-t-md">
            <h5 class="text-xl font-medium leading-normal text-gray-800" id="exampleModalLabel">เข้าสู่ระบบ
            </h5>
            <button type="button"
              class="box-content w-4 h-4 p-1 text-black border-none rounded-none opacity-50 btn-close focus:shadow-none focus:outline-none focus:opacity-100 hover:text-black hover:opacity-75 hover:no-underline"
              data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="relative p-4 modal-body">
            เข้าสู่ระบบ หรือ ลงทะเบียนสมาชิกก่อนบริจาค เพื่อสะสมคะแนน CUCI-POINT และรับ Gift voucher หากท่านไม่ต้องการใช้สิทธิ์นี้  กดข้าม
          </div>
          <div class="flex flex-wrap items-center justify-end flex-shrink-0 p-4 border-t border-gray-200 modal-footer rounded-b-md">
            <!-- <button type="button" class="px-6 py-2.5 bg-purple-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-purple-700 hover:shadow-lg focus:bg-purple-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-purple-800 active:shadow-lg transition duration-150 ease-in-out" data-bs-dismiss="modal">ข้าม</button> -->
            <router-link to="login">
              <button type="button" class="px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1" data-bs-dismiss="modal">เข้าสู่ระบบ / ลงทะเบียน</button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- \.ฟอร์มแจ้งเตือนให้เข้าสู่ระบบ -->

</template>

<script>
    import http from '@/services/AuthService'
    import filter from '@/helpers/filter'
    export default {
        mounted(){
            this.retreiveProject()
            window.scrollTo({top: 0, behavior: 'smooth'});
        },
        data(){
            return{
                projects: [],
                project_id: '',
                money: '',
                input_money: '',
                serverError: {},
                disabled: false,
                showModal: false
            }
        },
        computed: {
          logedIn(){
            return this.$store.getters['authMember/logedIn']
          },
          myCart(){
            return this.$store.getters['authMember/cart']
          }
        },
        watch:{
          input_money: function(){
            this.money = this.input_money
          }
        },
        methods:{
          insertTocart(){
            this.disabled = true
            let data = new FormData()
            data.append('project_id', this.project_id)
            data.append('money', this.money)
            http.post(`donate/insertToCart`, data)
              .then(() => {
                this.money = ''
                this.$store.dispatch("authMember/retreiveCart")
                this.showModal = false
                this.$store.dispatch("authMember/changeBackdrop")
                const Toast = this.$swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 1000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", this.$swal.stopTimer);
                    toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                  },
                });

                Toast.fire({
                  icon: "success",
                  title: "เพิ่มเข้าตะกร้าเรียบร้อย",
                })
                
                this.disabled = false
                this.serverError = {}
              })
              .catch(error => {
                if(error.response.status == 401 || error.response.status == 419){ //ถ้า token หมดอายุ
                    this.$store.dispatch('authMember/unauthenticateResetting')
                  }  
                this.disabled = false
                this.serverError = error.response.data.errors
              })
          },
          closeModal(){
            this.showModal = false
            this.$store.dispatch("authMember/changeBackdrop")  
          },
          selectProject(project_id){
            this.project_id = project_id
            this.showModal = true
            this.$store.dispatch("authMember/changeBackdrop")
          },
          selectMoney(money){
            this.money = money
            this.input_money = ''
          },
          retreiveProject(){
            http.get('frontendProject/projects')
                .then(response => {
                    this.projects = response.data.projects.data.map((item) => {
                        return Object.assign(item, {image: process.env.VUE_APP_URL_COOKIE+'images/project/banner/'+item.banner})
                    })
                })
                .catch(error => {
                  if(error.response.status == 401){ //ถ้า token หมดอายุ
                    this.$store.dispatch('authMember/unauthenticateResetting')
                  }  
                })
          },
          showMoney(money){
              return filter.numberFormat(money, '0,0.00')
          }
        },
    }
</script>

<style lang="css" scoped>
  .show-modal{
    display: block;
  }
  .hide-modal{
    display: none;
  }
</style>