<template>
   <section>
        <div class="w-full px-4 pt-40 mx-auto text-center bg-green-100 lg:mx-auto ">
            <h2 class="mb-2 text-4xl font-semibold leading-tight text-gray-800 capitalize">ติดต่อเรา</h2>
            <!-- <p>ผ่านช่องทางดังนี้</p> -->
            <p>มูลนิธิจูจีสร้างสรรค์สังคม </p>
            <p>CuCi Social Creation Foundation</p>
            <p>เลขที่  15 ซอยวสันต์พัฒนา 1 ถนนขวัญเมือง ตำบลสะเตง อำเภอเมืองยะลา จังหวัดยะลา 95000</p>
            <p>Email : cuci.pbf@gmail.com </p>
            <p>เบอร์ 087-837-8335 </p><br>
            <a href="https://web.facebook.com/cuci.pbf" class="mr-2 text-xl hover:text-indigo-200"><i class="fa-facebook-square fab"></i></a>
            <a href="https://lin.ee/1y0IPUU" class="mr-2 text-xl hover:text-indigo-200"><i class="fa-line fab"></i></a>
            <a href="https://www.messenger.com/t/686171485149705/?messaging_source=source%3Apages%3Amessage_shortlink&source_id=1441792&recurring_notification=0" class="mr-2 text-xl hover:text-indigo-200"><i class="fab fa-facebook-messenger"></i></a>
            
        </div>
        <div class="w-full bgcontent-center">
            <img src="@/assets/img/bg.png" class="2xl:w-full lg:w-full lg:h-700">
        </div>
    </section>
</template> 