<template>
    <div class="flex items-center min-h-screen p-6 py-40 bg-gray-50 dark:bg-gray-900">
      <div class="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div class="flex flex-col overflow-y-auto md:flex-row">
          <div class="h-32 md:h-auto md:w-1/2">
            <img
              aria-hidden="true"
              class="object-cover w-full h-full dark:hidden"
              src="@/assets/img/m8.jpg"
              alt="Office"/>
            <img
              aria-hidden="true"
              class="hidden object-cover w-full h-full dark:block"
              src="@/assets/img/m8.jpg"
              alt="Office"/>
          </div>

          <div class="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            
            <div class="w-full">

              <h1 class="mb-2 text-xl font-semibold text-gray-700 dark:text-gray-200">สมัครสมาชิกใหม่</h1>

              <form @submit.prevent="submitRegister()" class="mt-3">

                <label class="block mb-2 text-sm text-gray-700" for="fullname">ชื่อ</label>
                <input type="text" 
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                  :class="{'border-red-500': serverError.mem_name}"
                  v-model="mem_name"
                >
                <div v-if="serverError.mem_name" class="mt-2 text-sm text-red-500">
                  {{serverError.mem_name[0]}}
                </div>

                <label class="block mt-3 mb-2 text-sm text-gray-700" for="username">นามสกุล</label>
                <input type="text"
                  class="w-full px-3 py-2 leading-tight border rounded shadow appearance-none text -gray-700"
                  :class="{'border-red-500': serverError.mem_lastname}"
                  v-model="mem_lastname"  
                >
                <div v-if="serverError.mem_lastname" class="mt-2 text-xs text-red-500">
                  {{serverError.mem_lastname[0]}}
                </div>

                <!-- <label class="block mt-4 text-sm">
                  <span class="text-gray-700 dark:text-gray-400">
                    เพศ
                  </span>
                  <select 
                    class="block w-full px-3 py-2 text-sm border rounded shadow dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                    v-model="mem_lastname">
                    <option value="" style="display:none">--เลือกเพศ--</option>
                    <option value="1">ชาย</option>
                    <option value="2">หญิง</option>
                  </select>
                </label> -->

                <!-- <label class="block mt-3 mb-2 text-sm text-gray-700" for="mobile">ประเภทผู้ใช้งาน</label>
                <select class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow"
                  :class="{'border-red-500': serverError.mem_level_id}"
                  v-model="mem_level_id">
                  <option value="" style="display: none">--เลือกประเภทผู้ใช้--</option>
                  <option value="1">ผู้ใช้ทั่วไป</option>
                  <option value="2">ผู็ใช้องค์กร</option>
                </select>
                <div v-if="serverError.mem_level_id" class="mt-2 text-xs text-red-500">
                  {{serverError.mem_level_id[0]}}
                </div> -->

                <label class="block mt-3 mb-2 text-sm text-gray-700" for="mobile">เบอร์โทรศัพท์</label>
                <input type="text" 
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                  :class="{'border-red-500': serverError.mem_tel}"
                  v-model="mem_tel"
                >
                <div v-if="serverError.mem_tel" class="mt-2 text-xs text-red-500">
                  {{serverError.mem_tel[0]}}
                </div>

                <label class="block mt-3 mb-2 text-sm text-gray-700" for="email">
                  อีเมล <span class="text-red-500"> * คุณต้องใช้เข้ามูลนี้เพื่อเข้าสู่ระบบ *</span>
                </label>
                <input type="text" 
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                  :class="{'border-red-500': serverError.mem_email || serverError.email}" 
                  placeholder=""
                  v-model="mem_email">
                <div v-if="serverError.mem_email" class="mt-2 text-xs text-red-500">
                  {{serverError.mem_email[0]}}
                </div>
                <div v-if="serverError.email" class="mt-2 text-xs text-red-500">
                  {{serverError.email[0]}}
                </div>

                <label class="block mt-3 mb-2 text-sm text-gray-700" for="password">รหัสผ่าน</label>
                <input type="password"
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                  :class="{'border-red-500': serverError.password}"
                  v-model="password">
                  <div v-if="serverError.password" class="mt-2 text-xs text-red-500">
                  {{serverError.password[0]}}
                </div>

                <label class="block mt-3 mb-2 text-sm text-gray-700" for="confirm_password">ยืนยันรหัสผ่าน</label>
                <input type="password" 
                  class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none"
                  :class="{'border-red-500': serverError.confirm_password}"
                  v-model="confirm_password">
                  <div v-if="serverError.confirm_password" class="mt-2 text-xs text-red-500">
                  {{serverError.confirm_password[0]}}
                </div>

                <!-- <p class="my-4"></p>

                <label class="flex items-center dark:text-gray-400">
                  <input type="checkbox" class="text-purple-600 form-checkbox focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"/>
                  <span class="ml-2">ฉันยอมรับ <span class="underline">เงื่อนไขการใช้งานระบบ</span>
                  </span>
                </label> -->
                
                <button type="submit"
                  class="block w-full px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-purple-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-purple-700"
                  :class="{'opacity-50 cursor-not-allowed': disabled}"
                  :disabled="disabled">
                  สมัครสมาชิกใหม่
                </button>
              </form>

              
              <p class="my-5"></p>
              
              <button @click="useAuthProvider('google', null)" :disabled="disabled" :class="{'opacity-50 cursor-not-allowed': disabled}" type="submit" class="block w-full px-4 py-2 text-sm font-medium leading-5 text-center text-white transition-colors duration-150 bg-orange-600 border border-transparent rounded-lg cursor-pointer active:bg-purple-600 hover:bg-orange-500" >
                สมัครสมาชิกด้วยบัญชี google
              </button>
              <!-- <button
                class="flex items-center justify-center w-full px-4 py-2 text-sm font-medium leading-5 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray">
                <svg
                  class="w-4 h-4 mr-2"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  fill="currentColor">
                  <path
                    d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
                  />
                </svg>
                Github
              </button>
              <button
                class="flex items-center justify-center w-full px-4 py-2 mt-4 text-sm font-medium leading-5 transition-colors duration-150 border border-gray-300 rounded-lg dark:text-gray-400 active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray">
                <svg
                  class="w-4 h-4 mr-2"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  fill="currentColor">
                  <path
                    d="M23.954 4.569c-.885.389-1.83.654-2.825.775 1.014-.611 1.794-1.574 2.163-2.723-.951.555-2.005.959-3.127 1.184-.896-.959-2.173-1.559-3.591-1.559-2.717 0-4.92 2.203-4.92 4.917 0 .39.045.765.127 1.124C7.691 8.094 4.066 6.13 1.64 3.161c-.427.722-.666 1.561-.666 2.475 0 1.71.87 3.213 2.188 4.096-.807-.026-1.566-.248-2.228-.616v.061c0 2.385 1.693 4.374 3.946 4.827-.413.111-.849.171-1.296.171-.314 0-.615-.03-.916-.086.631 1.953 2.445 3.377 4.604 3.417-1.68 1.319-3.809 2.105-6.102 2.105-.39 0-.779-.023-1.17-.067 2.189 1.394 4.768 2.209 7.557 2.209 9.054 0 13.999-7.496 13.999-13.986 0-.209 0-.42-.015-.63.961-.689 1.8-1.56 2.46-2.548l-.047-.02z"/>
                </svg>
                Twitter
              </button> -->

              <p class="mt-2">
                <router-link to="/login" class="text-sm font-medium text-purple-600 dark:text-purple-400 hover:underline">ฉันเป็นสมาชิกอยู่แล้ว ? เข้าสู่ระบบ</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
  // Button M1
  import { Providers} from 'universal-social-auth'
  // Button M2
  import { Google} from 'universal-social-auth'
  import http from '@/services/AuthService'
  export default {
    data(){
      return{
        //ฟอร์มลงทะเบียนสมาชิก
        mem_name: '',
        mem_lastname: '',
        // mem_gender: '',
        mem_tel: '',
        mem_email: '',
        mem_level_id: '',
        password: '',
        confirm_password: '',

        //progress variable
        disabled: false,

        //server error
        serverError: [],

        //google login
        responseData: {},
        hash: '',
        data: {},
        fauth: false,
        // Github,
        // Twitch,
        // Facebook,
        Google,
        // Twitter,
      }
    },
    methods:{
      useAuthProvider (provider, proData) {
        this.disabled = true
        http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
          .then(() => {
            const pro = proData
            const ProData = pro || Providers[provider]
            this.disabled = true
            this.$Oauth.authenticate(provider, ProData)
              .then((response) => {
                // console.log(response)
                const rsp = response
                if (rsp.code) {
                  this.responseData.code = rsp.code
                  this.responseData.provider = provider
                  this.useSocialLogin()
                  // this.$Progress.finish()
                }
              }).catch(() => {
                // console.log(err)
                // this.$Progress.fail()
            })
          })
      },
      useSocialLogin(){
        // otp from input Otp form
        // hash user data in your backend with Cache or save to database
        const pdata = { code: this.responseData.code, otp: this.data.tok, hash: this.hash }
        // console.log(getBasicProfile())
        // this.$axios.post('socialAuth/google/signup/' + this.responseData.provider, pdata)
        this.$Progress.start()
        http.post(`socialAuth/google/signup/${this.responseData.provider}`, pdata)
          .then(response => {
            this.$store.dispatch('authMember/socialSignIn', response.data)
            // console.log(response.data)
            this.serverError = "";
            this.disabled = false
            this.$Progress.finish()
            
            const Toast = this.$swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "กำลังเข้าสู่ระบบ...",
            }).then(() => {
              this.disabled = false;
              this.$Progress.finish();
              this.$router.push({ name: "Riba" });
            });
            // const Toast = this.$swal.mixin({
            //   toast: true,
            //   position: "top-end",
            //   showConfirmButton: false,
            //   timer: 2000,
            //   timerProgressBar: true,
            //   didOpen: (toast) => {
            //     toast.addEventListener("mouseenter", this.$swal.stopTimer);
            //     toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            //   },
            // });
            //.then(async (response) => {
            // `response` data base on your backend config
            // console.log(response)
            // if (response.data.status === 444) {
            //   this.hash = response.data.hash
            //   this.fauth = true // Option show Otp form incase you using 2fa or any addition security apply to your app you can handle all that from here
            // }else if (response.data.status === 445) {
            //   //do something Optional
            // }else {
            //   await this.useLoginFirst(response.data.u)
            // }
        }).catch(() => {
          // console.log(err)
          this.disabled = false
          this.$Progress.fail()
        })
      },
      submitRegister(){
        //progressbar & button disabled
        this.disabled = true
        this.$Progress.start()
              
        let data = new FormData()
        data.append('mem_name', this.mem_name)
        data.append('mem_lastname', this.mem_lastname)
        // data.append('mem_gender', this.mem_gender)
        data.append('mem_tel', this.mem_tel)
        data.append('mem_email', this.mem_email)
        data.append('mem_level_id', this.mem_level_id)
        data.append('password', this.password)
        data.append('confirm_password', this.confirm_password)

        http.get(process.env.VUE_APP_URL_COOKIE+'sanctum/csrf-cookie')
          .then(() => {
            http.post(`member/signUp`, data)
              .then(() => {
                this.$Progress.finish() 
                this.disabled = false
                this.serverError = ''
  
                //sweet alert popup
                  const Toast = this.$swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                      toast.addEventListener('mouseenter', this.$swal.stopTimer)
                      toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                  }
                  })
                  
                  Toast.fire({
                      icon: 'success',
                      title: 'สมัครสมาชิกสำเร็จ'
                  }).then(() => {
                    this.$router.push({name: 'Login'})
                  })
  
              })
              .catch(error => {
                this.serverError = error.response.data.errors
                this.$Progress.fail()
                this.disabled = false
              })
          })
        
      }
    }   
  }
</script>

<style lang="scss" scoped>

</style>