<template>
    <div class="container pt-40 pb-20 pl-4 mx-auto">
        <div class="max-w-md overflow-hidden bg-white shadow-md lg:mx-10 rounded-xl md:max-w-2xl">
     <div class="md:flex">
       <!-- <div class="md:shrink-0">
         <img class="object-cover w-full h-48 md:h-full md:w-48" src="@/assets/img/riba.png" alt="Man looking at item at a store">
       </div> -->
       <!-- <div class="p-8">
         <div class="text-sm font-semibold tracking-wide text-indigo-500 uppercase">Case study</div>
         <a href="#" class="block mt-1 text-lg font-medium leading-tight text-black hover:underline">Finding customers for your new business</a>
         <p class="mt-2 text-slate-500">Getting a new business off the ground is a lot of hard work. Here are five ideas you can use to find your first customers.</p>
       </div> -->
     </div>
   </div>
   
   <div class="mb-8 lg:mx-60 ">
               
               <div class="px-8 py-8 space-y-2 bg-white shadow-lg lg:mx-20 rounded-xl sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                   <img class="block h-24 mx-auto sm:mx-0 sm:shrink-0" src="@/assets/img/derma_logo.png" alt="Woman's Face">
                   <div class="space-y-2 text-center sm:text-left">
                                                                     <!-- <router-link to="/cleanuptype"> -->
   
                       <button class="px-4 py-1 text-sm font-semibold text-purple-600 border border-purple-200 rounded-full hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">ซากาต</button>
                     <!-- </router-link> -->
                   </div>
               </div>
               
           </div>
   
          
            
       <!-- Cards -->
       <h2 class="content-center my-6 text-2xl font-semibold text-gray-700 lg:mx-80 dark:text-gray-200">
           รายละเอียดการจ่ายซากาต
       </h2>
 
   
       <!-- New Table -->
       <div class="overflow-hidden rounded-lg shadow-xs lg:mx-80">
           <div class="w-full overflow-x-auto">
           <table class="w-full whitespace-no-wrap">
               <thead>
                   <tr class="text-xs font-semibold tracking-wide text-left uppercase border-b text-black-500 dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                       <th colspan="4" class="px-4 py-3 font-semibold">ID : DN{{ transactionData.id }}</th>
                       
                   </tr>
               </thead>
               <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                <tr class="text-gray-700 dark:text-gray-400">
                       <td class="px-4 py-3">
                       <div class="flex items-center text-sm">
                          
                           <div>
                           <p class="font-semibold">สถานะ</p>
                           <p class="text-xs text-gray-600 dark:text-gray-400">
                           </p>
                           </div>
                       </div>
                       </td>
                       <td class="px-4 py-3 text-sm">
                        <span v-if="status == 'pending'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                    รอชำระ 
                        </span>
                        <span v-else-if="status == 'waiting'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                            กำลังตรวจสอบ
                        </span>
                        <span v-else-if="status == 'uncomplete'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                            หลักฐานไม่ถูกต้อง
                        </span>
                        <span v-else-if="status == 'success'" class="px-2 py-1 font-semibold leading-tight bg-green-200 rounded-full text-white-700 dark:bg-green-700 dark:text-green-100">
                            ชำระแล้ว
                        </span>
                        <span v-else-if="status == 'cancel'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                            ยกเลิก
                        </span>
                       </td>
                       
                       
                   </tr>
                   <tr class="text-gray-700 dark:text-gray-400">
                       <td class="px-4 py-3">
                       <div class="flex items-center text-sm">
                          
                           <div>
                           <p class="font-semibold">เวลาที่สร้าง</p>
                           <p class="text-xs text-gray-600 dark:text-gray-400">
                           </p>
                           </div>
                       </div>
                       </td>
                       <td colspan="3" class="px-4 py-3 text-sm">
                        {{ formatDate(transactionData.created_at) }}
                       </td>
                       
                       
                   </tr>
   
                  
                   <tr class="text-gray-700 dark:text-gray-400">
                       <td class="px-4 py-3">
                       <div class="flex items-center text-sm">
                           
                           <div>
                           <p class="font-semibold">เวลาที่อัพเดต</p>
                           <p class="text-xs text-gray-600 dark:text-gray-400">
                           </p>
                           </div>
                       </div>
                       </td>
                       <td class="px-4 py-3 text-sm">
                        {{ formatDate(transactionData.updated_at) }}
                       </td>
                       
                   </tr>
                   
             
                   <tr class="text-gray-700 dark:text-gray-400">
                       <td class="px-4 py-3">
                       <div class="flex items-center text-sm">
                           
                           <div>
                           <p class="font-semibold">จำนวนเงิน</p>
                           <p class="text-xs text-gray-600 dark:text-gray-400">
                           </p>
                           </div>
                       </div>
                       </td>
                       <td class="px-4 py-3 text-sm">
                       {{ showMoney(transactionData.amount) }} บาท
                       </td>
                       
                   </tr>
                   <tr class="text-gray-700 dark:text-gray-400">
                       <td class="px-4 py-3">
                       <div class="flex items-center text-sm">
                           
                           <div>
                           <p class="font-semibold">วิธีการชำระเงิน</p>
                           <p class="text-xs text-gray-600 dark:text-gray-400">
                           </p>
                           </div>
                       </div>
                       </td>
                       <td class="px-4 py-3 text-sm">
                       Banking
                       </td>
                       
                   </tr>
               </tbody>
           </table>
           <br>
           <table class="w-full whitespace-no-wrap">
               <thead>
                   <tr class="text-xs font-semibold tracking-wide text-left uppercase border-b text-black-500 dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                       <th colspan="4" class="px-4 py-3 font-semibold">โครงการที่บริจาค</th>
                       
                   </tr>
               </thead>
               <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                <tr class="text-gray-700 dark:text-gray-400" v-for="(item, index) in cartDetail" :key="index">
                       <td class="px-4 py-3">
                       <div class="flex items-center text-sm">
                          
                           <div>
                           <p class="font-semibold">{{ item.name }}</p>
                           <p class="text-xs text-gray-600 dark:text-gray-400">
                           </p>
                           </div>
                       </div>
                       </td>
                       <td class="px-4 py-3 text-sm">
                       {{ showMoney(item.amount) }} บาท
                       </td>
                       
                       
                   </tr>
                   
   
                  
                   <tr class="text-gray-700 dark:text-gray-400">
                       <td class="px-4 py-3">
                       <div class="flex items-center text-sm">
                           
                           <div>
                           <p class="font-semibold">รวมเงินที่บริจาค</p>
                           <p class="text-xs text-gray-600 dark:text-gray-400">
                           </p>
                           </div>
                       </div>
                       </td>
                       <td class="px-4 py-3 text-sm">
                        {{ showMoney(transactionData.amount) }} บาท
                       </td>
                       
                   </tr>
                   
             
               </tbody>
           </table>
           </div>
           <!-- <router-link to="/Riba-canceled" class="ml-2 buttonderma">ดูใบเสร็จ</router-link> -->
           <!-- <router-link to="/Riba-canceled" class="ml-2 buttonderma">รับเกียรติบัติ</router-link> -->


           <router-link to="/zakatHistory" class="ml-2 buttoncuci">กลับ</router-link>
           <div>
            <br>
           </div>
           <img class="mx-auto w-82 " :src="`${imagePath}images/slip/thumbnail/${bankingTransaction.transfer_file}`" alt="screenshot" >
       </div>
   
        </div>
   </template>
   
<script>
    import http from '@/services/AuthService'
    import filter from '@/helpers/filter'
    import moment from 'moment'
    export default {
        mounted(){
            this.showDetail()
        },
        data(){
            return{
                cartDetail: [],
                transactionData: '',
                bankingTransaction: {
                    transfer_file: '',
                    confirm_status: '',
                },
                imagePath: '',
                status: ''
            }
        },
        methods:{
            showDetail(){
                http.get(`cart/cardDetail/${this.$route.params.order_id}`)
                    .then(response => {
                        this.isShowDetail = true
                        this.cartDetail = response.data.data
                        this.transactionData = response.data.transaction_data
                        if(response.data.banking_transaction != null){
                            this.bankingTransaction = response.data.banking_transaction
                        }
                        this.imagePath = process.env.VUE_APP_URL_COOKIE
                        this.status = response.data.status
                        // let totalMoney = 0
                        // response.data.data.forEach(element => {
                        //     totalMoney = parseFloat(totalMoney + element.amount)
                        // });
                        // this.totalMoney = totalMoney
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            showMoney(money){
              return filter.numberFormat(money, '0,0.00')
            },
            formatDate(value){
                if(value){
                    return moment(String(value)).format('DD/MM/YYYY HH:m')
                }
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>