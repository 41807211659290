<template>
    <div class="container pt-20 pb-20 pl-4 mx-auto lg:pt-40">
        <div class="max-w-md overflow-hidden bg-white shadow-md lg:mx-10 rounded-xl md:max-w-2xl">
            <div class="md:flex">
            <!-- <div class="md:shrink-0">
                <img class="object-cover w-full h-48 md:h-full md:w-48" src="@/assets/img/riba.png" alt="Man looking at item at a store">
            </div> -->
            <!-- <div class="p-8">
                <div class="text-sm font-semibold tracking-wide text-indigo-500 uppercase">Case study</div>
                <a href="#" class="block mt-1 text-lg font-medium leading-tight text-black hover:underline">Finding customers for your new business</a>
                <p class="mt-2 text-slate-500">Getting a new business off the ground is a lot of hard work. Here are five ideas you can use to find your first customers.</p>
            </div> -->
            </div>
        </div>
   
        <div class="w-full mb-8 ">
            <div class="px-8 py-8 space-y-2 bg-white shadow-lg lg:mx-20 rounded-xl sm:py-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
                
                <!-- บริจาค -->
                <img class="block h-24 mx-auto sm:mx-0 sm:shrink-0" src="@/assets/img/derma_logo.png" alt="Woman's Face">
                <div class="space-y-2 text-center sm:text-left">
                    <router-link to="/donateHistory">
                        <button  class="px-4 py-1 text-sm font-semibold text-white bg-purple-600 border border-purple-200 rounded-full text-white-600 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">บริจาค</button>
                    </router-link>
                </div>
                <!-- \.บริจาค -->

                <!-- สะสางดอกเบี้ย -->
                <img class="block h-24 mx-auto sm:mx-0 sm:shrink-0" src="@/assets/img/derma_logo.png" alt="Woman's Face">
                <div class="space-y-2 text-center sm:text-left">
                    <router-link to="/ribaHistory">
                        <button class="px-4 py-1 text-sm font-semibold text-purple-600 border border-purple-200 rounded-full hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">สะสางดอกเบี้ย</button>
                    </router-link>
                </div>
                <!-- \.สะสางดอกเบี้ย -->

                <!-- ซากาต -->
                <img class="block h-24 mx-auto sm:mx-0 sm:shrink-0" src="@/assets/img/derma_logo.png" alt="Woman's Face">
                <div class="space-y-2 text-center sm:text-left">
                    <router-link to="/zakatHistory">
                        <button class="px-4 py-1 text-sm font-semibold text-purple-600 border border-purple-200 rounded-full hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2">ซากาต</button>
                    </router-link>
                </div>
                <!-- \.ซากาต -->

            </div>
        </div>
       
        <!-- Cards -->
        <h2 class="my-6 text-2xl font-semibold text-gray-700 lg:mx-20 dark:text-gray-200">
            ประวัติ / บริจาค
        </h2>
        
        <div class="inline-block px-4 py-2 lg:mx-20">
            <button @click="retreivePendingHistory()" class="mb-2 ml-2 buttonderma" :class="{'buttonderma-selected': showTap == 'pending'}">ยังไม่ชำระ</button>
            <button @click="retreiveDonateHistoryWithStatus(2,1, 'waiting')" class="mb-2 ml-2 buttonderma" :class="{'buttonderma-selected': showTap == 'waiting'}">กำลังตรวจสอบ</button>
            <button @click="retreiveDonateHistoryWithStatus(4,1, 'uncomplete')" class="mb-2 ml-2 buttonderma" :class="{'buttonderma-selected': showTap == 'uncomplete'}">หลักฐาน banking ไม่ตรง</button>
            <button @click="retreiveDonateHistoryWithStatus(2,2, 'success')" class="mb-2 ml-2 buttonderma" :class="{'buttonderma-selected': showTap == 'success'}">ชำระแล้ว</button>
            <button @click="retreiveDonateHistoryCanceled('cancel')" class="mb-2 ml-2 buttonderma" :class="{'buttonderma-selected': showTap == 'cancel'}">ยกเลิก</button>
        </div>
   
       <!-- New Table -->
       <div class="overflow-hidden rounded-lg shadow-xs lg:mx-20">
            <div class="w-full overflow-x-auto">
                
                <table class="w-full whitespace-no-wrap" v-if="!isShowDetail">
                    <thead>
                        <tr class="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th class="px-4 py-3">วัน เดือน ปี</th>
                            <th class="px-4 py-3">จำนวนเงิน (บาท)</th>
                            <th class="px-4 py-3">สถานะ</th>
                            <th class="px-4 py-3">รายละเอียด</th>
                            <th class="px-4 py-3" v-if="showTap == 'pending'">ยกเลิก</th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        <tr class="text-gray-700 dark:text-gray-400" v-for="(item, index) in histories" :key="index">
                            <td class="px-4 py-3">
                                <div class="flex items-center text-sm">
                                    <div>
                                        <p class="font-semibold"> {{formatDate(item.pay_create_date)}}</p>
                                        <p class="text-xs text-gray-600 dark:text-gray-400">
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                {{showMoney(item.amount)}}
                            </td>
                            <td class="px-4 py-3 text-xs">
                                <span v-if="showTap == 'pending'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                    รอชำระ 
                                </span>
                                <span v-else-if="showTap == 'waiting'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                    กำลังตรวจสอบ
                                </span>
                                <span v-else-if="showTap == 'uncomplete'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                    หลักฐานไม่ถูกต้อง
                                </span>
                                <span v-else-if="showTap == 'success'" class="px-2 py-1 font-semibold leading-tight bg-green-200 rounded-full text-white-700 dark:bg-green-700 dark:text-green-100">
                                    ชำระแล้ว
                                </span>
                                <span v-else-if="showTap == 'cancel'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                    ยกเลิก
                                </span>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                <router-link v-if="showTap == 'pending'" :to="{name: 'CheckoutAgain', params: {order_id: item.order_id}}" class="cuci-text">
                                    ไปหน้าชำระ
                                </router-link>
                                <router-link v-else-if="showTap == 'waiting'" :to="{name: 'DonateOrderDetail', params: {'order_id': item.order_id}}" class="cuci-text">
                                    ดูรายละเอียด
                                </router-link>
                                <router-link v-else-if="showTap == 'uncomplete'" :to="{name: 'CheckoutAgain', params: {order_id: item.order_id}}" class="cuci-text">
                                    ไปหน้าชำระอีกครั้ง
                                </router-link>
                                <router-link v-else-if="showTap == 'success'" :to="{name: 'DonateOrderDetail', params: {'order_id': item.order_id}}" class="cuci-text">
                                    ดูรายละเอียด
                                </router-link>
                                <router-link v-else-if="showTap == 'cancel'" :to="{name: 'DonateOrderDetail', params: {'order_id': item.order_id}}" class="cuci-text">
                                    ดูรายละเอียด
                                </router-link>
                            </td>
                            <td class="px-4 py-3 text-sm cursor-pointer" v-if="showTap == 'pending'">
                                <span @click="cancelTransaction(item.order_id)">ยกเลิก</span> 
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- รายละเอียด -->
                <table class="w-full whitespace-no-wrap" v-if="isShowDetail">
                    <thead>
                   <tr class="text-xs font-semibold tracking-wide text-left uppercase border-b text-black-500 dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                       <th colspan="4" class="px-4 py-3 font-semibold">ID : DN{{ transactionData.id }} </th>
                       
                   </tr>
                    </thead>
                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                            <div class="flex items-center text-sm">
                                
                                <div>
                                <p class="font-semibold">สถานะ</p>
                                <p class="text-xs text-gray-600 dark:text-gray-400">
                                </p>
                                </div>
                            </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                <span v-if="showTap == 'pending'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                    รอชำระ 
                                </span>
                                <span v-else-if="showTap == 'waiting'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                    กำลังตรวจสอบ
                                </span>
                                <span v-else-if="showTap == 'uncomplete'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                    หลักฐานไม่ถูกต้อง
                                </span>
                                <span v-else-if="showTap == 'success'" class="px-2 py-1 font-semibold leading-tight bg-green-200 rounded-full text-white-700 dark:bg-green-700 dark:text-green-100">
                                    ชำระแล้ว
                                </span>
                                <span v-else-if="showTap == 'cancel'" class="px-2 py-1 font-semibold leading-tight text-orange-700 bg-orange-100 rounded-full dark:bg-green-700 dark:text-green-100">
                                    ยกเลิก
                                </span>
                            </td>
                            
                            
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                            <div class="flex items-center text-sm">
                                
                                <div>
                                <p class="font-semibold">เวลาที่สร้าง</p>
                                <p class="text-xs text-gray-600 dark:text-gray-400">
                                </p>
                                </div>
                            </div>
                            </td>
                            <td colspan="3" class="px-4 py-3 text-sm">
                                {{ formatDate(transactionData.created_at) }}
                                </td>
                            
                            
                        </tr>
        
                        
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                            <div class="flex items-center text-sm">
                                
                                <div>
                                <p class="font-semibold">เวลาที่อัพเดต</p>
                                <p class="text-xs text-gray-600 dark:text-gray-400">
                                </p>
                                </div>
                            </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                {{ formatDate(transactionData.updated_at) }}
                            </td>
                            
                        </tr>
                        
                    
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                            <div class="flex items-center text-sm">
                                
                                <div>
                                <p class="font-semibold">จำนวนเงิน</p>
                                <p class="text-xs text-gray-600 dark:text-gray-400">
                                </p>
                                </div>
                            </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                {{showMoney(transactionData.amount)}} บาท
                            </td>
                            
                        </tr>
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                            <div class="flex items-center text-sm">
                                
                                <div>
                                <p class="font-semibold">วิธีการชำระเงิน</p>
                                <p class="text-xs text-gray-600 dark:text-gray-400">
                                </p>
                                </div>
                            </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                            Banking
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
                <br>
                <table class="w-full whitespace-no-wrap" v-if="isShowDetail">
                    <thead>
                        <tr class="text-xs font-semibold tracking-wide text-left uppercase border-b text-black-500 dark:border-gray-700 bg-gray-50 dark:text-gray-400 dark:bg-gray-800">
                            <th colspan="4" class="px-4 py-3 font-semibold">โครงการที่บริจาค</th>
                            
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                        <tr class="text-gray-700 dark:text-gray-400" v-for="(item, index) in cartDetail" :key="index">
                            <td class="px-4 py-3" >
                            <div class="flex items-center text-sm">
                                <div>
                                <p class="font-semibold">{{item.name}}</p>
                               
                                </div>
                            </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                {{ showMoney(item.amount) }} บาท
                            </td>
                            
                        </tr>
        
                        
                        <tr class="text-gray-700 dark:text-gray-400">
                            <td class="px-4 py-3">
                            <div class="flex items-center text-sm">
                                
                                <div>
                                <p class="font-semibold">รวมเงินที่บริจาค</p>
                                <p class="text-xs text-gray-600 dark:text-gray-400">
                                </p>
                                </div>
                            </div>
                            </td>
                            <td class="px-4 py-3 text-sm">
                                {{ showMoney(transactionData.amount) }} บาท
                            </td>
                            
                        </tr>
                        
                    
                    </tbody>
                </table>
                <!-- \.รายละเอียด -->

                <!-- <router-link to="/Riba-canceled" class="ml-2 buttonderma">ดูใบเสร็จ</router-link>
                <router-link to="/Riba-canceled" class="ml-2 buttonderma">รับเกียรติบัติ</router-link> -->

           </div>
       </div>
   
        </div>
   </template>
   
<script>
    import http from '@/services/AuthService'
    import filter from '@/helpers/filter'
    import moment from 'moment'
    export default {
        mounted(){
            window.scrollTo({top: 0, behavior: 'smooth'});
            this.retreivePendingHistory()
        },
        data(){
            return {
                histories: [],
                showTap: 'pending',
                isShowDetail: false,
                cartDetail: [],
                transactionDate: '',
                totalMoney: 0,
                transactionData: '',
            }
        },
        methods:{
            cancelTransaction(order_id){
                this.$swal.fire({
                    title: 'ยืนยันการยกเลิก',
                    text: "ต้องการยกเลิกใช่หรือไม่",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'ไม่ใช่',
                    confirmButtonText: 'ใช่'
                    }).then((result) => {
                    if (result.isConfirmed) {
                        http.delete(`cart/cancel/${order_id}`)
                        .then(() => {
                            this.retreivePendingHistory()
                            this.$swal.fire(
                                'ยกเลิกเรียบร้อย!',
                                'ตะกร้าบริจาคของท่านถูกยกเลิกแล้ว',
                                'success'
                            )
                        })
                        .catch(error => {
                            if(error.response.statu == 401){
                                this.$store.dispatch('authMember/unauthenticateResetting')
                            }
                        })
                    }
                    })
            },
            showDetail(order_id){
                http.get(`cart/cardDetail/${order_id}`)
                    .then(response => {
                        this.isShowDetail = true
                        this.cartDetail = response.data.data
                        this.transactionData = response.data.transaction_data
                        // let totalMoney = 0
                        // response.data.data.forEach(element => {
                        //     totalMoney = parseFloat(totalMoney + element.amount)
                        // });
                        // this.totalMoney = totalMoney
                    })
                    .catch(error => {
                        if(error.response.statu == 401){
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }
                    })
            },
            retreiveDonateHistoryCanceled(showTap){
                http.get(`donate/historyCanceled`)
                    .then(response => {
                        this.histories = response.data.data.data
                        this.showTap = showTap
                        this.isShowDetail = false
                    })
                    .catch((err) => {
                        if(err.response.status == 401){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }  
                    });
            },
            retreiveDonateHistoryWithStatus(transactionStatus, confirmStatus, showTap){
                http.get(`donate/historyWithStatus/${transactionStatus}/${confirmStatus}`)
                    .then(response => {
                        this.histories = response.data.data.data
                        this.showTap = showTap
                        this.isShowDetail = false
                    })
                    .catch((err) => {
                        if(err.response.status == 401){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }  
                    });
            },
            retreivePendingHistory(){
                http.get('donate/pendingHistory')
                    .then((result) => {
                        this.histories = result.data.data.data
                        this.showTap = 'pending'
                        this.isShowDetail = false
                    })
                    .catch((err) => {
                        if(err.response.status == 401){ //ถ้า token หมดอายุ
                            this.$store.dispatch('authMember/unauthenticateResetting')
                        }  
                    });
            },
            showMoney(money){
              return filter.numberFormat(money, '0,0.00')
            },
            formatDate(value){
                if(value){
                    return moment(String(value)).format('DD/MM/YYYY HH:m')
                }
            },
        }
    }
</script>
   
   <style lang="scss" scoped>
   
   </style>